import { convertPixelToRem, getBoxWidthAndHeight } from 'css-blocks-styled-components';
import styled, { css } from 'styled-components';

interface SmallLoaderProps {
  $autoCenter?: boolean;
  $greyBackgroundOnDisabled?: boolean;
}

export const SmallLoader = styled.div<SmallLoaderProps>`
  ${getBoxWidthAndHeight(20)};
  font-size: ${convertPixelToRem(10)};
  text-indent: -9999em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);

  ${({ $autoCenter }) =>
    $autoCenter &&
    css`
      margin: 0 auto;
    `};

  &:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: ${({ theme }) => theme.palette.primary.main};
    transition: background-color 0.3s ease;

    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  button:disabled & {
    &:after {
      background: ${({ $greyBackgroundOnDisabled, theme }) =>
        $greyBackgroundOnDisabled ? theme.palette.grey[300] : theme.palette.primary.main};
    }
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
