import { Column } from 'components/Containers';
import { FC } from 'react';
import { HugeLoading } from '../../../components/Loaders/HugeLoading';

import { Content } from './styles';

interface LoginValidationProps {
  children?: never;
}

const LoginValidation: FC<LoginValidationProps> = () => {
  return (
    <Content>
      <Column gap={24}>
        <h2>Validando credenciais...</h2>

        <HugeLoading />
      </Column>
    </Content>
  );
};

export default LoginValidation;
