import {
  ButtonAndSearchContainer,
  Container,
  Count,
  HeaderPaper,
  PaginationContainer,
  PaperContainer,
  StyledBox,
  StyledDivUsers,
  StyledP,
  StyledSpan,
  StyledTableCell,
  StyledTableCellHeader,
  StyledTableHead,
  StyledUserName,
  UserAndCountContainer,
  UserContainer,
  UserInfoContainer,
} from './styles';
import { StyledButton, StyledButtonNoBackGround } from 'components/Buttons/styled';
import UserIcon from 'components/Icons/User';
import {
  CircularProgress,
  FormControlLabel,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from 'components/Material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import UserCicle from 'components/Icons/UserCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import SwitchComponent from 'components/Switch';

import { useEffect, useState } from 'react';

import apiRoutes from 'services/apiRoutes';

import { PaginatedResult, User } from 'types/entities';

import { useToast } from 'providers/Toast';

import UserModal from './components/Modal';

import { PaginationItem } from '@mui/material';

import { getRoleName } from './helpers';

import SkeletonTableUsers from './components/Skeleton';

import { Mode, Type, UserType } from './types';

import CustomTextField from 'components/Inputs/TextField';
import { useAuth } from 'providers/Auth';

const ITEMS_PER_PAGE = 10;
const FIRST_PAGE = 1;

function UserRegister(): JSX.Element {
  const { user } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [paginatedUsers, setPaginatedUsers] = useState<PaginatedResult<User>>();

  const [loading, setLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(FIRST_PAGE);

  const [role, setRole] = useState<string[]>([Type.SYS_CURATOR]);

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const [searchTerm, setSearchTerm] = useState('');

  const [email, setEmail] = useState('');

  const [userData, setUserData] = useState<UserType | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [userCreated, setUserCreated] = useState(false);

  const [mode, setMode] = useState(Mode.ADD);

  const [isPageLoading, setPageLoading] = useState(false);

  const [editingUserId, setEditingUserId] = useState('');

  const { addToast } = useToast();

  const fetchUsers = async (): Promise<void> => {
    setPageLoading(true);

    setLoading(true);

    const response = await apiRoutes.fetchPaginatedUsers(
      {
        page,
        itemsPerPage: ITEMS_PER_PAGE,
      },

      searchTerm,
    );

    setPaginatedUsers(response);

    setPageLoading(false);
  };

  useEffect(() => {
    fetchUsers();

    if (userCreated) {
      fetchUsers();
      setUserCreated(false);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreated, page]);

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);

    setSelectedUsers([]);

    setMode(Mode.ADD);

    setUserData(null);

    setEmail('');
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const handleChangeUserStatus = async (userId: string, checked: boolean): Promise<void> => {
    setEditingUserId(userId);

    const userUpdated = await apiRoutes.updateUser(userId, {
      isActive: !checked,
    });

    if (userUpdated) {
      const updatedUsers = paginatedUsers?.data.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            isActive: !checked,
          };
        }
        return user;
      });

      setPaginatedUsers(
        paginatedUsers && {
          ...paginatedUsers,
          data: updatedUsers || [],
        },
      );

      setEditingUserId('');

      const toastMessage = checked
        ? 'Usuário desativado com sucesso.'
        : 'Usuário ativado com sucesso.';

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: toastMessage,
      });
    }
  };

  const handleSearchButtonClick = async (): Promise<void> => {
    fetchUsers();
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const handleSelectCardUser = (userEmail: string): void => {
    if (selectedUsers.includes(userEmail)) {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter((email) => email !== userEmail),
      );
    } else {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userEmail]);
    }
  };

  const handleSelectRole = (_: React.MouseEvent<HTMLElement>, newRoles: string[] | null): void => {
    if (newRoles && newRoles.length > 0) {
      setRole(newRoles);

      if (userData) {
        setUserData({
          ...userData,
          roles: newRoles,
        });
      }
    }
  };

  const findUserByEmail = async (email: string): Promise<void> => {
    try {
      setIsLoading(true);

      setMode(Mode.ADD);

      const response = await apiRoutes.getUserByEmail(email);

      if (response) {
        setUserData(response);

        setIsLoading(false);
      } else {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Nenhum usuário encontrado com o e-mail fornecido.',
        });
        setIsLoading(false);

        setUserData(null);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Nenhum usuário encontrado com o e-mail fornecido.',
      });
      setUserData(null);

      setIsLoading(false);
    }
  };

  const handleEditUser = (userEmail: string): void => {
    const userToEdit = paginatedUsers?.data.find((user) => user.email === userEmail);

    if (userToEdit) {
      setUserData(userToEdit);

      setRole(userToEdit.roles);

      setEmail(userToEdit.email);

      setSelectedUsers([userToEdit.email]);

      setMode(Mode.EDIT);

      setIsModalOpen(true);
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Usuário não encontrado.',
      });
    }
  };

  return (
    <Container>
      <h2>Cadastro de Usuário</h2>

      <UserModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        email={email}
        setEmail={setEmail}
        isLoading={isLoading}
        findUserByEmail={findUserByEmail}
        user={userData}
        selectedUsers={selectedUsers}
        handleSelectCardUser={handleSelectCardUser}
        role={role}
        handleSelectRole={handleSelectRole}
        openModal={openModal}
        setUserCreated={setUserCreated}
        mode={mode}
      />

      <PaperContainer>
        <HeaderPaper>
          <UserAndCountContainer>
            <StyledDivUsers>
              <StyledSpan>Usuários</StyledSpan>
              <StyledBox>
                {loading ? (
                  <CircularProgress size={16} />
                ) : (
                  <Count>{paginatedUsers?.meta.total}</Count>
                )}
              </StyledBox>
            </StyledDivUsers>

            <StyledP>Veja todos os usuários cadastrados na plataforma.</StyledP>
          </UserAndCountContainer>

          <ButtonAndSearchContainer>
            <StyledButton
              variant="contained"
              endIcon={<UserIcon color="white" />}
              onClick={openModal}
            >
              Adicionar Usuários
            </StyledButton>

            <CustomTextField
              placeholder="Pesquisar"
              value={searchTerm}
              onChange={handleChangeSearch}
              onClear={() => setSearchTerm('')}
            />

            <StyledButton variant="contained" onClick={handleSearchButtonClick}>
              Buscar
            </StyledButton>
          </ButtonAndSearchContainer>
        </HeaderPaper>
        {isPageLoading ? (
          <SkeletonTableUsers />
        ) : (
          <>
            <TableContainer>
              <Table aria-label="simple table">
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCellHeader>Nome</StyledTableCellHeader>

                    <StyledTableCellHeader>Status</StyledTableCellHeader>

                    <StyledTableCellHeader>Tipo</StyledTableCellHeader>

                    <StyledTableCellHeader>Email</StyledTableCellHeader>

                    <StyledTableCellHeader></StyledTableCellHeader>
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {paginatedUsers?.data?.map((userItem: User) => (
                    <TableRow key={userItem.email}>
                      <TableCell>
                        <UserContainer>
                          <UserCicle color="#636973" />

                          <UserInfoContainer>
                            <StyledUserName>{userItem.name}</StyledUserName>
                          </UserInfoContainer>
                        </UserContainer>
                      </TableCell>

                      <TableCell>
                        <FormControlLabel
                          control={
                            <SwitchComponent
                              disabled={userItem.id === user.data.id}
                              userId={userItem.id}
                              checked={userItem.isActive}
                              onChange={() =>
                                handleChangeUserStatus(userItem.id, userItem.isActive)
                              }
                            />
                          }
                          label=""
                        />
                      </TableCell>

                      <StyledTableCell>{getRoleName(userItem.roles)}</StyledTableCell>

                      <StyledTableCell>{userItem.email}</StyledTableCell>

                      <TableCell align="right">
                        <StyledButtonNoBackGround
                          variant="contained"
                          disabled={userItem.id === user.data.id}
                          onClick={() => handleEditUser(userItem.email)}
                          endIcon={<ManageAccountsIcon style={{ fontSize: '25px' }} />}
                        >
                          {editingUserId === userItem.id ? (
                            <CircularProgress size={16} />
                          ) : (
                            'Gerenciar'
                          )}
                        </StyledButtonNoBackGround>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <PaginationContainer>
          <Pagination
            count={paginatedUsers?.meta.pageAmount}
            disabled={paginatedUsers?.meta.pageAmount === 1}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => {
              if (item.type === 'previous') {
                return (
                  <PaginationItem
                    {...item}
                    sx={{ border: '1px solid #CED3D9' }}
                    component={ArrowBackIcon}
                  />
                );
              }
              if (item.type === 'next') {
                return (
                  <PaginationItem
                    {...item}
                    sx={{ border: '1px solid #CED3D9' }}
                    component={ArrowForwardIcon}
                  />
                );
              }

              return <PaginationItem sx={{ color: '#7B868C' }} {...item} />;
            }}
          />
        </PaginationContainer>
      </PaperContainer>
    </Container>
  );
}

export default UserRegister;
