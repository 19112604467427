import { ReactNode } from 'react';

import { Typography } from 'components/Material';
import { Row } from 'components/Containers';

import { ModalWrapper, StyledDialog } from './styles';
import { RemoveButton } from 'components/Buttons';

interface ModalProps {
  children: ReactNode;
  title: string | JSX.Element;
  gap?: number;
  width?: string | number;
  maxWidth?: string | number;
  isOpen: boolean;
  close: () => void;
  allowEscape?: boolean;
}

function Modal({
  title,
  gap = 16,
  width = '',
  maxWidth = '',
  isOpen,
  close,
  children,
  allowEscape = true,
}: ModalProps): JSX.Element {
  const modalWidth = typeof width === 'string' ? width : `${width}px`;
  const modalMaxWidth = typeof maxWidth === 'string' ? maxWidth : `${maxWidth}px`;

  return (
    <StyledDialog maxWidth="xl" onClose={allowEscape ? close : undefined} open={isOpen}>
      <ModalWrapper gap={gap} $width={modalWidth} $maxWidth={modalMaxWidth}>
        <Row justifyContent="space-between">
          <Row fullWidth={false}>
            <Typography variant="h6" fontWeight={600} color="Gray">
              {title}
            </Typography>
          </Row>

          <RemoveButton onClick={close} />
        </Row>

        {children}
      </ModalWrapper>
    </StyledDialog>
  );
}

export default Modal;
