import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  padding: 10px;
  text-align: left;
`;

export const SwitchContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
`;

export const Text = styled.p`
  color: #666;
`;
