import { Routes, Route } from 'react-router';

import CustomRoute, { RouteProps } from './Route';

import { appRoutes } from './config';

function AppRoutes(): JSX.Element {
  return (
    <Routes>
      {Object.values(appRoutes).map(
        ({ path, isOpen, routerProps, requiredRoles, ...customRouteProps }) => (
          <Route
            key={path}
            path={path}
            {...routerProps}
            element={
              <CustomRoute
                requiredRoles={requiredRoles}
                isPrivate={!isOpen}
                {...(customRouteProps as RouteProps)}
              />
            }
          />
        ),
      )}
    </Routes>
  );
}

export default AppRoutes;
