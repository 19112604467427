import { FC } from 'react';
import { useAuth } from '../../providers/Auth';

import BaseSignInWrapper from './Base';
import LoginValidation from './Validation';
import SignInForm from './SignInForm';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';

import { tLoginAction } from '../../types/auth';

type ActionToComponet = {
  [key in Exclude<tLoginAction, null>]: FC | null;
};

const actionToComponent: ActionToComponet = {
  login: SignInForm,
  'validating-code': LoginValidation,
  'forgot-password': ForgotPassword,
  'forgot-password-sent': null,
  'change-password-after-login': ChangePassword,
  'change-password-with-code': ChangePassword,
};

const Auth: FC = () => {
  const { loginAction } = useAuth();

  if (!loginAction) return null;

  const Component = actionToComponent[loginAction];

  if (!Component) return null;

  return (
    <BaseSignInWrapper>
      <Component />
    </BaseSignInWrapper>
  );
};

export default Auth;
