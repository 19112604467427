import { Container, Icon, Title, Text } from './styles';

const NotFound = (): JSX.Element => {
  return (
    <Container>
      <Icon />
      <Title>Página não encontrada</Title>
      <Text>Desculpe, a página que você está procurando não existe.</Text>
    </Container>
  );
};

export default NotFound;
