import { Box, Card, CardProps, TableCell, TableHead, TypographyProps } from '@mui/material';
import { Paper, Typography } from 'components/Material';
import { convertPixelToRem } from 'css-blocks-styled-components';
import styled from 'styled-components';

interface StyledCardProps extends CardProps {
  isSelected: boolean;
}

interface StyledTypographyProps extends TypographyProps {
  isSelected: boolean;
}

export const Container = styled.div`
  max-width: 100%;

  h2 {
    color: #474d59;
    font-size: ${convertPixelToRem(20)};
    font-weight: 700;
    margin-left: ${convertPixelToRem(10)};
  }
`;

export const PaperContainer = styled(Paper)`
  margin-top: ${convertPixelToRem(10)};
  border-radius: ${convertPixelToRem(10)};
  flex-grow: 1;
  width: 100%;

  @media (max-width: 600px) {
    margin: ${convertPixelToRem(10)};
    padding: ${convertPixelToRem(10)};

    max-width: 79vw;
  }
`;

export const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: ${convertPixelToRem(18)};
  color: #474d59;

  @media (max-width: 600px) {
    font-size: ${convertPixelToRem(16)};
  }
`;

export const StyledP = styled.p(() => ({
  display: 'block',
  fontSize: '14px',
  fontWeight: '400',
  color: '#636973',
}));

export const UserAndCountContainer = styled.div`
  display: block;
  padding: ${convertPixelToRem(20)};

  @media (max-width: 600px) {
    padding: ${convertPixelToRem(10)};
  }
`;

export const ButtonAndSearchContainer = styled.div`
  display: flex;
  gap: ${convertPixelToRem(10)};
  padding: ${convertPixelToRem(20)};
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;
    padding: ${convertPixelToRem(10)};
  }
`;

export const HeaderPaper = styled(Paper)`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;

    border-radius: 8px 8px 0 0;
  }
`;

export const Count = styled.span`
  font-weight: bold;
  font-size: ${convertPixelToRem(14)};
  color: #00b5ad;
  margin-right: ${convertPixelToRem(5)};
  padding: 5px 8px;
  border-radius: ${convertPixelToRem(20)};
  background-color: #e7faf9;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${convertPixelToRem(20)};
  padding-bottom: ${convertPixelToRem(20)};
`;

export const StyledDivUsers = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertPixelToRem(5)};
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserInfoContainer = styled.div`
  margin-left: ${convertPixelToRem(10)};
`;

export const HospitalText = styled.div`
  font-size: 0.8em;
  color: gray;
`;

export const TitleModal = styled.span`
  font-size: ${convertPixelToRem(18)};
  color: #474d59;
  font-weight: bold;
`;

export const SubTitle = styled.span`
  font-size: ${convertPixelToRem(14)};
  color: #474d59;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${convertPixelToRem(10)};
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: ${convertPixelToRem(15)};
`;

export const UserName = styled(Typography)`
  max-width: 300px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const StyledCard = styled(Card)<StyledCardProps>`
  && {
    background-color: ${({ isSelected }) => (isSelected ? '#00B5AD' : '#ffffff')};
    color: ${({ isSelected }) => (isSelected ? '#ffffff' : 'gray')};
    cursor: pointer;
    border: 2px solid #00b5ad;
    box-shadow: none;
  }
`;

export const StyledTypography = styled(Typography)<StyledTypographyProps>`
  && {
    background-color: ${({ isSelected }) => (isSelected ? '#00B5AD' : '#ffffff')};
    color: ${({ isSelected }) => (isSelected ? '#ffffff' : 'gray')};
    cursor: pointer;
  }
`;

export const StyledTypographyPermission = styled(Typography)`
  && {
    margin-top: ${convertPixelToRem(20)};
    margin-bottom: ${convertPixelToRem(10)};
  }
`;

export const StyledDiv = styled.div`
  width: 100%;
  margin-top: ${convertPixelToRem(15)};
`;

export const StyledDivCardBody = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTableCellHeader = styled(TableCell)`
  && {
    color: #535353;
    font-weight: 600;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    color: #989ea6;
    font-weight: 600;
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
    background: #f4f4f4;
    color: #989ea6;
  }
`;

export const StyledUserName = styled.div`
  color: #636973;
`;

export const StyledBox = styled(Box)`
  && {
    display: 'flex';
    align-items: 'center';
    margin-left: '4px';
  }
`;
