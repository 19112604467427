import { Column, Row } from 'components/Containers';
import { FC, ReactNode } from 'react';
import { Container, Content, StyledAppLogo } from './styles';

interface BaseSignInWrapperProps {
  children: ReactNode;
}

const BaseSignInWrapper: FC<BaseSignInWrapperProps> = ({ children }) => {
  return (
    <Container>
      <Content>
        <Column position="relative">
          <Row justifyContent="center">
            <StyledAppLogo src="/icons/logo.png" alt="OC Identity logo" />
          </Row>

          <span>Login</span>

          {children}
        </Column>
      </Content>
    </Container>
  );
};

export default BaseSignInWrapper;
