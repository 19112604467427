import styled from 'styled-components';
import { MdErrorOutline } from 'react-icons/md';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 2em;
  color: #333;
`;

export const Text = styled.p`
  color: #666;
`;

export const Icon = styled(MdErrorOutline)`
  font-size: 5em;
  color: #ff6b6b;
  margin-bottom: 20px;
`;
