import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

interface CustomTooltipProps {
  title: string;
  placement: 'bottom' | 'top' | 'left' | 'right';
  includePrefix?: boolean;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#00B5AD',
    color: 'white',
    fontSize: theme.typography.pxToRem(15),
    padding: '10px',
    borderRadius: '8px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#00B5AD',
  },
}));

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  title,
  placement,
  includePrefix = false,
}) => {
  return (
    <StyledTooltip
      title={
        <>
          {includePrefix && <span style={{ color: '#FFF74A' }}>1° Fila: </span>}
          {title}
        </>
      }
      placement={placement}
      arrow
    >
      <NewReleasesIcon sx={{ fontSize: 25 }} color="primary" />
    </StyledTooltip>
  );
};

export default CustomTooltip;
