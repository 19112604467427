import { CSSProperties } from 'react';
import { css, FlattenSimpleInterpolation } from 'styled-components';

const CAPITALIZED_LETTER = /[A-Z]/g;

function convertCamelToCSS(camelCaseProp: string): string {
  const asCSSformatted = camelCaseProp.replace(
    CAPITALIZED_LETTER,
    (matched) => `-${matched.toLowerCase()}`,
  );

  return asCSSformatted;
}

export function convertCSSinJS(props: CSSProperties): FlattenSimpleInterpolation {
  const style = Object.entries(props).reduce((accCss, [flexProp, value]) => {
    const cssProp = convertCamelToCSS(flexProp);

    const cssLine = `${cssProp}: ${typeof value === 'number' ? `${value}px` : value};`;

    return `${accCss}${cssLine}`;
  }, '');

  return css`
    ${style}
  `;
}
