type UserRole = 'SYS_ADMIN' | 'SYS_CURATOR' | 'SYS_SUPER_ADMIN';

export const getRoleName = (roles: string[]): string => {
  const roleNames: { [key in UserRole]?: string } = {
    SYS_ADMIN: 'Administrador',
    SYS_CURATOR: 'Curador',
    SYS_SUPER_ADMIN: 'Super Administrador',
  };

  return roles.map((role) => roleNames[role as UserRole] || role).join(', ');
};
