import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiRoutes from 'services/apiRoutes';
import { Box } from '@mui/material';
import {
  StyledArrowForwardIcon,
  StyledDiv,
  StyledTextField,
  StyledTextFieldRedBorder,
  UserSummary,
} from './styles';
import UserInfo from './components/UserInfo';
import { Virtuoso } from 'react-virtuoso';
import Scroller from './components/Scroll/Scroller';

import { CircularProgress } from 'components/Material';
import { getRoleName } from 'pages/Users/helpers';
import { LogData, UserChange, UserData, UserLog, UserLogData } from './types';
import { fieldNames } from './helpers';

function Log(): JSX.Element {
  const { logName } = useParams();

  const [logData, setLogData] = useState<LogData>({ before: [], after: [] });

  const [userData, setUserData] = useState<UserData | null>(null);

  const [userDataResponse, setUserDataResponse] = useState<UserData | null>(null);

  const [patientId, setPatientId] = useState<string>('');

  const [dataTime, setDataTime] = useState<string>('');

  const [event, setEvent] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const [body, setBody] = useState({});

  useEffect(() => {
    const fetchLogData = async (): Promise<void> => {
      setLoading(true);

      if (!logName) return;

      const response = await apiRoutes.getLog(logName);

      if (response.request) {
        setUserData(response.request.user);

        setPatientId(response.request.params.patientSk);

        setDataTime(response.request.dateTime);

        setEvent(response.request.event);

        setBody(response.request.body);

        setLoading(false);
      }

      if (response.response) {
        setLogData({ before: response.response.data.before, after: response.response.data.after });
        setLoading(false);

        setUserDataResponse(response.response.data.after);
      }
    };

    fetchLogData();
  }, [logName]);

  const isUserLog = (data: UserLog | LogData): data is UserLog => {
    return data && 'id' in data.before;
  };

  const processUserLog = (logData: UserLog): UserChange[] => {
    const userFields: (keyof UserLogData)[] = ['isActive', 'roles'];

    return userFields.map((field) => {
      let beforeValue = logData.before[field];
      let afterValue = logData.after[field];

      if (field === 'roles') {
        beforeValue = getRoleName(beforeValue as string[]);
        afterValue = getRoleName(afterValue as string[]);
      }

      if (field === 'isActive') {
        beforeValue = beforeValue ? 'Ativado' : 'Desativado';
        afterValue = afterValue ? 'Ativado' : 'Desativado';
      }

      return {
        beforeItem: {
          value: beforeValue !== null && beforeValue !== undefined ? beforeValue.toString() : '',
          attributeDescription: fieldNames[field] || field,
        },
        afterItem: {
          value: afterValue !== null && afterValue !== undefined ? afterValue.toString() : '',
          attributeDescription: fieldNames[field] || field,
        },
      };
    });
  };

  const processFieldLog = (logData: LogData): UserChange[] => {
    const uniqueAttributes = new Set(
      [...logData.before, ...logData.after].map((item) => item.attribute),
    );

    return Array.from(uniqueAttributes).map((attribute) => {
      const beforeItem = logData.before.find((item) => item.attribute === attribute) || {
        value: '',
        attributeDescription: '',
      };

      const afterItem = logData.after.find((item) => item.attribute === attribute) || {
        value: '',
        attributeDescription: '',
      };

      return { beforeItem, afterItem };
    });
  };

  const uniqueAttributesArray: UserChange[] = isUserLog(logData)
    ? processUserLog(logData as UserLog)
    : processFieldLog(logData as LogData);

  const filteredData = uniqueAttributesArray.filter(({ beforeItem, afterItem }) =>
    [beforeItem, afterItem].some(
      (item) =>
        item.attributeDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.value.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );

  return (
    <>
      {userData && (
        <UserSummary>
          <UserInfo
            patientSk={patientId}
            userName={userData.name}
            userEmail={userData.email}
            dataTime={dataTime}
            event={event}
            body={body}
            userDataResponse={userDataResponse}
          />
        </UserSummary>
      )}

      {loading ? (
        <>
          <Box display="flex" alignItems="center" justifyContent="center" minHeight="80vh">
            <CircularProgress size={26} />
          </Box>
        </>
      ) : (
        <>
          <Virtuoso
            data={filteredData}
            components={{ Scroller }}
            itemContent={(_, { beforeItem, afterItem }) => (
              <StyledDiv>
                <StyledTextFieldRedBorder
                  label={beforeItem?.attributeDescription || afterItem.attributeDescription}
                  variant="outlined"
                  size="small"
                  value={beforeItem.value || '-'}
                />

                <StyledArrowForwardIcon />

                <StyledTextField
                  label={beforeItem?.attributeDescription || afterItem.attributeDescription}
                  variant="outlined"
                  size="small"
                  value={afterItem.value || '-'}
                />
              </StyledDiv>
            )}
            style={{ height: '800px', width: '100%', marginTop: '20px' }}
          />
        </>
      )}
    </>
  );
}

export default Log;
