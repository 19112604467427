import React from 'react';
import { PatientInformations } from '../styles';

interface PatientInformationsProps {
  patientSk?: string | null;
  selectedPatient?: {
    unityName?: string | null;
    cidCode?: string | null;
    isDead?: string | null;
    percentage?: number | null;
  };
}

const PatientInfo: React.FC<PatientInformationsProps> = ({ patientSk, selectedPatient }) => {
  return (
    <PatientInformations>
      <div>
        <span>SK: </span>
        {patientSk}
      </div>
      <div>
        <span>Unidade: </span>
        {selectedPatient?.unityName}
      </div>
      <div>
        <span>CID: </span>
        {selectedPatient?.cidCode}
      </div>
      <div>
        <span>Óbito: </span>
        {selectedPatient?.isDead}
      </div>
      <div>
        <span>Total Curado: </span>
        {selectedPatient?.percentage + '%'}
      </div>
    </PatientInformations>
  );
};

export default PatientInfo;
