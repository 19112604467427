import { Origins } from './types';

export const DATE_NAME = 'Date';
export const ONE_TYPE_CHECKBOX = 'Check_box';
export const TWO_TYPE_CHECKBOX = 'check_box';

export const tabs = [
  {
    getLabel: () => 'Paciente',
    id: 'tab1',
    render: true,
  },
  {
    getLabel: () => 'Histórico Saúde',
    id: 'tab2',
    render: true,
  },
  {
    getLabel: () => 'Lab',
    id: 'tab3',
    render: true,
  },
  {
    getLabel: () => 'Patologia',
    id: 'tab4',
    render: true,
  },
  {
    getLabel: () => 'Genômica',
    id: 'tab5',
    render: true,
  },
  {
    getLabel: () => 'Tratamento',
    id: 'tab6',
    render: true,
  },
  {
    getLabel: () => 'Tratamento Sistêmico',
    id: 'tab7',
    render: true,
  },
  {
    getLabel: () => 'TMO',
    id: 'tab8',
    render: true,
  },
  {
    getLabel: () => 'Desfecho',
    id: 'tab9',
    render: true,
  },
];

export const originOptions = [
  { value: 'origin', label: 'Origem' },
  { value: 'prescription', label: 'Prescrição' },
  { value: 'clinicalNotes', label: 'Nota Clínica' },
  { value: 'anamnese', label: 'Anamnese' },
];

export const DomainCategory = {
  treatment: 'tratamento',
  systemicTreatment: 'tratamento_sistemico',
  pathology: 'patologia',
  outcome: 'desfecho',
  lab: 'lab',
  genomic: 'genomica',
  comorbidity: 'historico_saude',
  patient: 'paciente',
  tmo: 'tmo',
};

export const OriginOptions: Record<string, string> = {
  origin: 'Origem',
  prescription: 'Prescrição',
  clinicalNotes: 'Nota Clínica',
  anamnese: 'Anamnese',
};

export const HEIGHT = 790;
export const FIRST_CURATORSHIP = 'FIRST_CURATORSHIP';
export const SECOND_CURATORSHIP = 'SECOND_CURATORSHIP';

export const initialOrigins: Origins = {
  anamnesis: [],
  clinicalNotes: [],
  origin: [],
  prescription: [],
};

export const formatData = (dataString: string | number | null): string => {
  if (dataString === null) return '';

  if (!dataString) return '';

  let data: Date;

  if (typeof dataString === 'string') {
    const partes = dataString.split('-');
    if (partes.length === 3) {
      data = new Date(parseInt(partes[0]), parseInt(partes[1]) - 1, parseInt(partes[2]));
    } else {
      return 'Formato de data inválido';
    }
  } else {
    data = new Date(dataString * 1000);
  }

  if (!isNaN(data.getTime())) {
    return data.toLocaleDateString('pt-BR');
  }

  return 'Data inválida';
};
