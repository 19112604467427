/* eslint-disable prettier/prettier */
import React from 'react';
import { PatientInformations } from '../styles';
import { QueueType, UserType } from '../types';
interface PatientInformationsProps {
  patientSk?: string | null;
  userName?: string;
  userEmail?: string;
  dataTime?: string;
  event?: string;
  body?: any;
  userDataResponse?: any;
}

const UserInfo: React.FC<PatientInformationsProps> = ({
  patientSk,
  userEmail,
  userName,
  dataTime,
  event,
  body,
  userDataResponse,
}) => {
  const queueMapping: { [key in QueueType]: string } = {
    FIRST_CURATORSHIP: 'Primeira Fila',
    SECOND_CURATORSHIP: 'Segunda Fila',
  };

  const roleMapping: { [key in UserType]: string } = {
    SYS_ADMIN: "Administrador do Sistema",
    SYS_CURATOR: "Curador do Sistema",
  };

  return (
    <PatientInformations>
      {patientSk && (
        <div>
          <span>Sk Paciente: </span>
          {patientSk ? patientSk : 'N/A'}
        </div>
      )}

      <div>
        <span>Executor: </span>
        {userName ? userName : 'N/A'}
      </div>

      <div>
        <span>E-mail executor: </span>
        {userEmail ? userEmail : 'N/A'}
      </div>

      <div>
        <span>Data do Registro: </span>
        {dataTime ? dataTime : 'N/A'}
      </div>

      <div>
        <span>Evento: </span>
        {event ? event : 'N/A'}
      </div>

      {userDataResponse?.name && (
        <div>
          <span>Usuário: </span>
          {userDataResponse?.name ? userDataResponse.name : 'N/A'}
        </div>
      )}

      {userDataResponse?.email && (
        <div>
          <span>E-mail usuário: </span>
          {userDataResponse?.email ? userDataResponse.email : 'N/A'}
        </div>
      )}

      {userDataResponse?.id && (
        <div>
          <span>Id usuário: </span>
          {userDataResponse?.id ? userDataResponse.id : 'N/A'}
        </div>
      )}

      {userDataResponse?.roles && (
        <div>
          <span>Permissões: </span>
          {userDataResponse.roles.length > 0
            ? userDataResponse.roles
              .map((role: string) => roleMapping[role as UserType] || role)
              .join(', ')
            : 'N/A'}
        </div>
      )}

      {userDataResponse && (
        <div>
          <span>SK Paciente: </span>
          {body?.patient?.patientSk ? body.patient?.patientSk : 'N/A'}
        </div>
      )}

      {body?.queue && (
        <div>
          <span>Fila: </span>
          {queueMapping[body.queue as QueueType] || 'N/A'}
        </div>
      )}

      {patientSk && (
        <div>
          <span>Sk Paciente: </span>
          {body?.patient?.patientSk ? body.patient.patientSk : 'N/A'}
        </div>
      )}

      {body?.patient?.unityName && (
        <div>
          <span>Unidade: </span>
          {body?.patient?.unityName ? body.patient.unityName : 'N/A'}
        </div>
      )}

      {body?.patient?.cidCode && (
        <div>
          <span>CID: </span>
          {body?.patient?.cidCode ? body.patient.cidCode : 'N/A'}
        </div>
      )}

      {body?.patient?.isDead && (
        <div>
          <span>Óbito: </span>
          {body?.patient?.isDead ? body.patient.isDead : 'N/A'}
        </div>
      )}
    </PatientInformations>
  );
};

export default UserInfo;
