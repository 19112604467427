import { FC } from 'react';

import { SVG, IconProps } from './base';

const Edit: FC<IconProps> = ({ className, size = 24, color }) => {
  return (
    <SVG
      $size={size}
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1L21 5M1 21L2.2764 16.3199C2.35968 16.0145 2.40131 15.8619 2.46523 15.7195C2.52199 15.5931 2.59172 15.4729 2.67332 15.3609C2.76521 15.2348 2.87711 15.1229 3.1009 14.8991L13.4343 4.56569C13.6323 4.36768 13.7313 4.26867 13.8455 4.23158C13.9459 4.19895 14.0541 4.19895 14.1545 4.23158C14.2687 4.26867 14.3677 4.36768 14.5657 4.56569L17.4343 7.43431C17.6323 7.63232 17.7313 7.73133 17.7684 7.84549C17.8011 7.94591 17.8011 8.05409 17.7684 8.15451C17.7313 8.26867 17.6323 8.36768 17.4343 8.56569L7.1009 18.8991C6.87711 19.1229 6.76521 19.2348 6.63908 19.3267C6.52709 19.4083 6.40692 19.478 6.28052 19.5348C6.13815 19.5987 5.98548 19.6403 5.68014 19.7236L1 21Z"
        stroke={color ?? '#696969'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export default Edit;
