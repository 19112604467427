import styled from 'styled-components';

interface StyledLabelProps {
  $hasError?: boolean;
}

export const StyledLabel = styled.label<StyledLabelProps>`
  font-weight: 500;
  color: ${({ theme, $hasError }) =>
    $hasError ? theme.palette.error.main : theme.palette.grey[700]};
`;

export default StyledLabel;
