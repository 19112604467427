import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

import apiRoutes from 'services/apiRoutes';

import {
  CenteredContainer,
  ContainerInputs,
  FlexColumnContainer,
  FlexEndContainer,
  FlexGapContainer,
  ResponsiveText,
  SpaceBetweenContainer,
  StyledDiv,
} from '../styles';
import Select from 'components/Select';
import { CuratorshipData, DomainItem, Origins, PatientDataTabProps } from '../types';
import {
  DomainCategory,
  FIRST_CURATORSHIP,
  HEIGHT,
  SECOND_CURATORSHIP,
  initialOrigins,
  originOptions,
} from '../helpers';
import CustomInput from './CustomInput/CustomInputs';
import VirtuosoDataList from './Virtuoso';
import CustomTextField from 'components/Inputs/TextField';
import { useToast } from 'providers/Toast';
import CustomTooltip from 'components/Tooltip';
import { InputValues } from 'types/entities';

function TmoDataTab({
  patientSk,
  handleChangeInput,
  inputValues,
  group,
  origin,
  setOrigin,
  queueType,
}: PatientDataTabProps): JSX.Element {
  const { addToast } = useToast();

  const [isPageLoading, setPageLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const [domains, setDomains] = useState<DomainItem[]>([]);

  const [treatment, setTreatment] = useState<Origins>(initialOrigins);

  const [firstCuratorshipData, setFirstCuratorshipData] = useState<InputValues[]>([]);

  const fetchFirstCuratorshipData = async (): Promise<void> => {
    try {
      const response = await apiRoutes.fetchPatientData(patientSk, FIRST_CURATORSHIP);

      setFirstCuratorshipData(response);
    } catch (error) {
      console.log('Erro ao buscar dados da primeira fila de curadoria.');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const checkAndSetInitialDataOrigin = (patientData: any) => {

    if (patientData.origin && patientData.origin.length > 0) {
      setOrigin('origin');
    } else if (patientData.prescription && patientData.prescription.length > 0) {
      setOrigin('prescription');
    } else if (patientData.clinicalNotes && patientData.clinicalNotes.length > 0) {
      setOrigin('clinicalNotes');
    } else if (patientData.anamnesis && patientData.anamnesis.length > 0) {
      setOrigin('anamnese');
    }
  };

  const fetchTreatment = async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await apiRoutes.fetchTreatmentdData(patientSk);

      setTreatment(response);

      checkAndSetInitialDataOrigin(response)
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao buscar dados de tratamento.',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDomains = async (): Promise<void> => {
    try {
      setPageLoading(true);

      const response = await apiRoutes.fetchDomains(DomainCategory.tmo);

      setDomains(response);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao buscar domínios de tratamento.',
      });
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchTreatment();

    fetchDomains();

    fetchFirstCuratorshipData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findFirstCuratorshipData = (attributeName: string): CuratorshipData => {
    const item = firstCuratorshipData.find((input) => input.attribute === attributeName);

    return {
      value: item ? item.value : 'Nenhum dado',
      sourceOfInformation: item ? item.sourceOfInformation : '',
      service: item ? item.service : '',
      date: item ? item.date : null,
    };
  };

  const handleClear = (): void => {
    setSearchTerm('');
  };

  return (
    <>
      {isPageLoading ? (
        <CenteredContainer>
          <CircularProgress />
        </CenteredContainer>
      ) : (
        <SpaceBetweenContainer>
          <FlexColumnContainer>
            <FlexGapContainer>
              <CustomTextField
                placeholder="Pesquisar"
                value={searchTerm}
                onChange={handleSearchChange}
                onClear={handleClear}
              />

              <Select
                placeholder="Origem"
                minHeight={40}
                width={190}
                id="outlined-start-adornment"
                propConfig={{ unique: 'value', label: 'label' }}
                options={originOptions}
                value={origin}
                onChange={(value) => setOrigin(value)}
              />
            </FlexGapContainer>

            {loading ? (
              <CenteredContainer>
                <CircularProgress />
              </CenteredContainer>
            ) : (
              <>
                <VirtuosoDataList
                  origin={origin}
                  patientData={treatment}
                  searchTerm={searchTerm}
                  titleKey={'Atendimento'}
                  HEIGHT={HEIGHT}
                />
              </>
            )}
          </FlexColumnContainer>

          <StyledDiv>
            {domains?.map((item: DomainItem) => {
              const curatorshipData = findFirstCuratorshipData(item.attributeName);

              return (
                <FlexEndContainer key={item.attributeName}>
                  <ContainerInputs key={item.attributeName}>
                    {queueType === SECOND_CURATORSHIP && (
                      <CustomTooltip
                        title={curatorshipData.value}
                        placement="top"
                        includePrefix={true}
                      />
                    )}

                    <ResponsiveText>{item.attributeDescription}</ResponsiveText>

                    <CustomInput
                      attributeName={item.attributeName}
                      attributeType={item.attributeType}
                      attributeDescription={item.attributeDescription}
                      sourceOfInformation={curatorshipData.sourceOfInformation}
                      service={curatorshipData.service}
                      date={curatorshipData.date}
                      group={group}
                      origin={origin}
                      inputValues={inputValues}
                      handleChangeInput={handleChangeInput}
                      item={item}
                      category={DomainCategory.tmo}
                      queueType={queueType}
                    />
                  </ContainerInputs>
                </FlexEndContainer>
              );
            })}
          </StyledDiv>
        </SpaceBetweenContainer>
      )}
    </>
  );
}

export default TmoDataTab;
