import { ReactNode, useCallback } from 'react';

import { Dialog, Typography } from 'components/Material';
import { CloseIcon, ReportIcon } from 'components/Icons';
import { IconButton, Button } from 'components/Buttons';
import { DualRingLoader } from 'components/Loaders';
import { Row } from 'components/Containers';

import { useToggle } from 'hooks/toggle';

import { Container, IrreversibleWrapper } from './styles';

export interface ConfirmationModalProps {
  onConfirm: () => Promise<void>;
  cancel: () => void;
  text: ReactNode;
  title?: string;
  type?: 'normal' | 'irreversible';
  size?: 'small' | 'medium' | 'big';
}

function ConfirmationModal({
  cancel,
  onConfirm,
  text,
  type,
  size = 'small',
  title,
}: ConfirmationModalProps): JSX.Element {
  const { isOn, toggle } = useToggle();

  const executeConfirmation = useCallback(async () => {
    toggle();

    await onConfirm();

    toggle();
  }, [onConfirm, toggle]);

  const modalTitle = title || 'Você tem certeza?';

  return (
    <Dialog maxWidth="xl" open={!!text} onClose={cancel}>
      <Container className={size} gap={30}>
        <Row justifyContent="space-between">
          <Typography variant="h6" color="GrayText">
            {modalTitle}
          </Typography>

          <IconButton onClick={cancel}>
            <CloseIcon />
          </IconButton>
        </Row>

        <Row justifyContent="center">
          {typeof text === 'string' ? (
            <Typography variant="body1" color="GrayText">
              {text}
            </Typography>
          ) : (
            text
          )}
        </Row>

        {type === 'irreversible' && (
          <IrreversibleWrapper gap={8} justifyContent="center">
            <ReportIcon color="primary" />

            <Typography textAlign="center" variant="body1" color="GrayText">
              Essa ação é <strong>irreversível</strong>
            </Typography>
          </IrreversibleWrapper>
        )}

        <Row gap={24} justifyContent="center">
          <Button variant="outlined" onClick={cancel}>
            Cancelar
          </Button>

          <Button variant="contained" onClick={executeConfirmation}>
            Confirmar
          </Button>

          {isOn && <DualRingLoader />}
        </Row>
      </Container>
    </Dialog>
  );
}

export default ConfirmationModal;
