import React, { useState, useEffect } from 'react';
import { CircularProgress, FormControlLabel } from '@mui/material';
import { Container, SwitchContainer, Text } from './styles';
import SwitchComponent from 'components/Switch';
import apiRoutes from 'services/apiRoutes';
import { useToast } from 'providers/Toast';

const Settings = (): JSX.Element => {
  const [isChecked, setIsChecked] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    const fetchSecondQueueStatus = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const isEnabled = await apiRoutes.getSecondQueueIsEnable();

        setIsChecked(isEnabled);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSecondQueueStatus();
  }, []);

  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setIsLoading(true);

    try {
      const newStatus = event.target.checked;
      setIsChecked(newStatus);

      await apiRoutes.changeSecondQueueIsEnable();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: newStatus
          ? 'Segunda fila ativada com sucesso.'
          : 'Segunda fila desativada com sucesso.',
      });

      setIsLoading(false);
    } catch (error) {
      setIsChecked(!event.target.checked);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Text>
        Ativar ou desativar a funcionalidade da <strong>Segunda Fila de Curadoria</strong>.
      </Text>

      {isLoading && <CircularProgress size={30} />}
      {!isLoading && (
        <SwitchContainer>
          <FormControlLabel
            control={
              <SwitchComponent
                userId="0"
                disabled={isLoading}
                checked={isChecked}
                onChange={handleSwitchChange}
              />
            }
            label=""
          />
        </SwitchContainer>
      )}
    </Container>
  );
};

export default Settings;
