import { ReactNode, CSSProperties, useMemo, RefObject } from 'react';
import styled from 'styled-components';

import { convertCSSinJS } from 'styles/helper';

type FlexCss = Pick<
  CSSProperties,
  'gap' | 'alignItems' | 'justifyContent' | 'flexWrap' | 'position' | 'flex'
>;

export interface ColumnProps extends FlexCss {
  children: ReactNode;
  className?: string;
  as?: 'header' | 'div' | 'article' | 'footer';
  fullHeight?: boolean;
  fullWidth?: boolean;
  title?: string;
  containerRef?: RefObject<HTMLDivElement> | ((elem: HTMLDivElement | null) => void);
  minHeight?: string;
}

const ColumnBase = styled.div<{
  $fullHeight?: boolean;
  $fullWidth?: boolean;
  $style: FlexCss;
  $minHeight?: string;
}>`
  display: flex;
  flex-direction: column;

  ${({ $style }) => convertCSSinJS($style)}

  ${({ $fullHeight }) => $fullHeight && 'height: 100%;'};
  ${({ $minHeight }) => $minHeight && `min-height: ${$minHeight}`};
  ${({ $fullWidth }) => $fullWidth && 'width: 100%;'};
`;

function Column({
  children,
  className,
  as,
  title,
  fullHeight,
  fullWidth = true,
  gap = 16,
  containerRef,
  minHeight,
  ...styles
}: ColumnProps): JSX.Element {
  const actualStyles = useMemo(() => ({ ...styles, gap }), [gap, styles]);

  return (
    <ColumnBase
      title={title}
      as={as}
      $fullHeight={fullHeight}
      $fullWidth={fullWidth}
      $minHeight={minHeight}
      className={className}
      $style={actualStyles}
      ref={containerRef}
    >
      {children}
    </ColumnBase>
  );
}

export default Column;
