import styled, { css } from 'styled-components';

import {
  convertPixelToRem,
  flexMiddle,
  getPadding,
  noBorderNoOutline,
} from 'css-blocks-styled-components';

import { QUERIES } from 'utils/constants';

interface FormButtonProps {
  $loading?: boolean;
}

export const Container = styled.div`
  ${getPadding(0, 20)}
  ${flexMiddle}
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.primary.main};

  ${QUERIES.PHONES} {
    ${getPadding(0, 10)}
  }

  ${QUERIES.CUSTOM(300)} {
    padding: 0;
  }
`;

export const StyledAppLogo = styled.img``;

export const BackButtonAnchor = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
`;

export const Content = styled.div`
  text-align: center;
  border-radius: 8px;
  padding: ${convertPixelToRem(32)};
  background: #fff;

  max-width: max(25%, 450px);
  width: 100%;

  ${StyledAppLogo} {
    height: 2rem;

    @media (max-width: 600px) {
      height: 4rem;
    }

    @media (max-width: 400px) {
      height: 3rem;
    }
  }

  ${QUERIES.PHONES} {
    padding: ${convertPixelToRem(24)};
  }

  span {
    font-size: 25px;
    color: #344054;
    margin-bottom: 10px;
  }
`;

export const Form = styled.form`
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const FormButton = styled.button<FormButtonProps>`
  color: #fff;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  padding: ${convertPixelToRem(8)};
  border-radius: 10px;
  outline: none;
  height: 40px;

  transition: background-color 0.2s;

  ${noBorderNoOutline}
  ${flexMiddle}

  margin-top: ${convertPixelToRem(12)};

  div {
    width: 20px;
  }

  ${QUERIES.HOVER_ENABLED} {
    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.palette.primary.dark};
    }
  }

  strong {
    font-weight: 600;
  }

  ${({ $loading }) =>
    $loading &&
    css`
      justify-content: space-between;
    `}
`;

export const FormAdivise = styled.p`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: ${convertPixelToRem(20)};

  span {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
