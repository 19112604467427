import { ReactNode } from 'react';

import { useAuth } from 'providers/Auth';

import { APP_WRAPPER_ID } from 'utils/constants';

import {
  AppContainer,
  Container,
  ContentWrapper,
  StyledFullHeightWrapper,
  StyledRow,
} from './styles';

import SideBar from '../SideBar';

interface WrapperProps {
  children: ReactNode;
}

const AppWrapper: React.FC<WrapperProps> = ({ children }) => {
  const { loginAction } = useAuth();

  if (loginAction) return <>{children}</>;

  return (
    <Container>
      <SideBar>
        <StyledRow gap={8} alignItems="stretch">
          <StyledFullHeightWrapper manageOverflow={false}>
            <AppContainer>
              <ContentWrapper id={APP_WRAPPER_ID}>{children}</ContentWrapper>
            </AppContainer>
          </StyledFullHeightWrapper>
        </StyledRow>
      </SideBar>
    </Container>
  );
};

export default AppWrapper;
