import {
  Box,
  CircularProgress,
  Paper,
  Slider,
  Table,
  TableCell,
  TableCellProps,
  TableHead,
  Typography,
} from 'components/Material';
import styled from 'styled-components';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface StyledTableCellProps extends TableCellProps {
  isDisabled?: boolean;
}

export const Container = styled.div`
  max-width: 100%;

  h2 {
    color: #474d59;
    font-size: 20px;
    font-weight: 700;
    margin-left: 10px;
  }
`;

export const PaperContainer = styled(Paper)`
  margin-top: 10px;
  border-radius: 10px;
  flex-grow: 1;
  width: 100%;

  @media (max-width: 600px) {
    margin: 10px;
    padding: 10px;

    max-width: 79vw;
  }
`;

export const StyledTableCellHeader = styled(TableCell)`
  && {
    color: #535353;
    font-weight: 600;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    color: #989ea6;
    font-weight: 600;
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
    background: #f4f4f4;
    color: #989ea6;
  }
`;

export const StyledTableCellColorTextGreen = styled(TableCell)<StyledTableCellProps>`
  && {
    color: ${(props) => (props.isDisabled ? '#c0c0c0' : '#038983')};
    font-weight: 700;
  }
`;

export const StyledTable = styled(Table)`
  min-width: 650px;
`;

export const StyledSpanDisabled = styled.span`
  text-decoration: none;
  color: inherit;
`;

export const StyledBox = styled(Box)`
  && {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
`;

export const StyledSpan = styled.span(() => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: '700',
  fontSize: '18px',
  color: '#474D59',
  marginBottom: '5px',
  justifyContent: 'center',
}));

export const StyledP = styled.p(() => ({
  display: 'block',
  fontSize: '14px',
  fontWeight: '400',
  color: '#636973',
}));

export const UserAndCountContainer = styled.div(() => ({
  display: 'flex',
  padding: '15px',
}));

export const ButtonAndSearchContainer = styled.div(() => ({
  display: 'flex',
  gap: '25px',
  padding: '15px',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const HeaderPaper = styled(Paper)`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;

    border-radius: 8px 8px 0 0;
  }
`;

export const StyledSlider = styled(Slider)`
  .MuiSlider-thumb {
    background-color: #ffffff;
    color: #ffffff;
  }

  .MuiSlider-rail {
    height: 6px;
  }

  .MuiSlider-track {
    height: 6px;
    background-color: #00b5ad;
    border: none;
  }
`;

export const Count = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #00b5ad;
  margin-right: 5px;
  padding: 5px 8px;
  border-radius: 20px;
  background-color: #e7faf9;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserInfoContainer = styled.div`
  margin-left: 10px;
`;

export const HospitalText = styled.div`
  font-size: 0.8em;
  color: gray;
`;

export const TitleModal = styled.span`
  font-size: 18px;
  color: #474d59;
  font-weight: bold;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  color: #474d59;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledBoxFieldsCurator = styled.div`
  width: 170px;
  display: block;
  align-items: center;
  gap: 20px;
  text-align: center;

  span {
    color: gray;
    font-size: 13px;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    color: white;
    margin-left: 4px;
  }
`;

export const StyledBoxOpenPatient = styled.div`
  position: relative;
  display: inline-flex;
  cursor: pointer;
`;

export const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  color: #00b5ad;
  font-size: 25px;
  margin-top: 7px;
`;

export const StyledOpenInNewIconDisabled = styled(OpenInNewIcon)`
  color: rgb(192, 192, 192);
  font-size: 25px;
  margin-top: 7px;
  cursor: default;
`;

export const StyledCircularProgressWhite = styled(CircularProgress)`
  && {
    color: #f4f4f4;
    position: absolute;

    & .MuiCircularProgress-svg circle {
      stroke-linecap: round;
    }
  }
`;

export const StyledCircularProgressPrimary = styled(CircularProgress)`
  && {
    color: ${(props) => props.theme.palette.primary.main};
    z-index: 1;

    & .MuiCircularProgress-svg circle {
      stroke-linecap: round;
    }
  }
`;

export const StyledBoxPercentage = styled.div`
  position: relative;
  display: inline-flex;
`;

export const StyledTypography = styled(Typography)`
  && {
    color: #636973;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const StyledTabsContainer = styled.div`
  margin-bottom: 20px;
`;
