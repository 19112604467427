import { FC, FormEvent, ReactNode } from 'react';

import { Column } from 'components/Containers';
import { SmallLoader } from 'components/Loaders/ButtonLoader';

import { Form, FormButton } from './styles';

interface FormWrapperProps {
  loading: boolean;
  buttonText: string;
  onSubmit: (event: FormEvent) => Promise<void> | void;
  children: ReactNode;
}

const FormWrapper: FC<FormWrapperProps> = ({ onSubmit, loading, buttonText, children }) => {
  return (
    <Form onSubmit={onSubmit}>
      <Column>
        {children}

        <FormButton type="submit" disabled={loading} $loading={loading}>
          {loading && <div />}

          <strong>{buttonText}</strong>

          {loading && <SmallLoader />}
        </FormButton>
      </Column>
    </Form>
  );
};

export default FormWrapper;
