import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { StyledTableCellHeader, StyledTableHead } from '../styles';

function SkeletonTableLogs(): JSX.Element {
  const rows = Array.from({ length: 7 }, (_, index) => index + 1);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <StyledTableHead>
          <TableRow>
            <StyledTableCellHeader>Eventos</StyledTableCellHeader>

            <StyledTableCellHeader>Usuário</StyledTableCellHeader>

            <StyledTableCellHeader>E-mail</StyledTableCellHeader>

            <StyledTableCellHeader>Data</StyledTableCellHeader>

            <StyledTableCellHeader></StyledTableCellHeader>
          </TableRow>
        </StyledTableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow key={row}>
              <TableCell>
                <Skeleton variant="text" width={125} />
              </TableCell>

              <TableCell>
                <Skeleton variant="text" width={130} />
              </TableCell>

              <TableCell>
                <Skeleton variant="text" width={280} />
              </TableCell>

              <TableCell>
                <Skeleton variant="text" width={220} />
              </TableCell>

              <TableCell align="right">
                <Box position="relative" display="inline-flex">
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={40}
                    sx={{ marginLeft: '45px', borderRadius: '5px' }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SkeletonTableLogs;
