import { Table, TableCell, TableRow } from '@mui/material';
import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { convertPixelToRem } from 'css-blocks-styled-components';

import UpdateIcon from '@mui/icons-material/Update';

import PersonIcon from '@mui/icons-material/Person';

export const Container = styled.div`
  max-width: 100%;

  h2 {
    color: #474d59;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: #00b5ad;
`;

export const StyledPersonIcon = styled(PersonIcon)`
  color: #00b5ad;
`;

export const SpanColor = styled.span`
  font-size: ${convertPixelToRem(14)};
  font-weight: 600;
  color: #00b5ad;
`;

export const PatientSummary = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #00b5ad;
  padding: 15px;
  border-radius: 6px;
  flex-direction: column;

  span {
    font-size: 16px;
    font-weight: 700;
    color: #00b5ad;
  }
`;

export const StyledDiv = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PatientInformations = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  color: #474d59;

  span {
    font-size: 14px;
    font-weight: 700;
    color: #474d59;
  }
`;

export const Protocol = styled.div`
  display: flex;
  width: 100%;
  background-color: #038983;
  padding: 8px 20px;
  border-radius: 5px;

  margin-top: 30px;

  span {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
`;

export const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;

  span {
    font-size: 14px;
    font-weight: 600;
    color: #474d59;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
`;

export const StyledDivInput = styled.div`
  width: 300px;
`;

export const StyledDivTracking = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;

  margin-top: 20px;
`;

export const StyledDivButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-end;

  width: 400px;
`;

export const ContainerInputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  span {
    font-size: 14px;
    font-weight: 600;
    color: #474d59;
  }
`;

export const ResponsiveTextContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: end;
`;

export const ResponsiveText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  text-overflow: ellipsis;
  display: block;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-top: 20px;
`;

export const StyledTabsContainer = styled.div`
  margin-top: 10px;
`;

export const FlexColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FlexGapContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const CustomTableCell = styled(TableCell)`
  && {
    font-weight: 600;
    color: rgb(71, 77, 89);
  }
`;

export const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: none;
    border: 1px solid #dfdfdf;
    border-radius: 10px !important;

    margin: 5px !important;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    .MuiAccordionSummary-expandIcon {
      padding: 0;
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0;
  }
`;

export const StyledTable = styled(Table)`
  & {
    box-shadow: none;
    border: none;
  }
`;

export const StyledTableCell = styled(TableCell)`
  & {
    border-bottom: none;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:last-child > .MuiTableCell-root {
    border-bottom: none;
  }
`;

export const IconTextWrapper = styled.div`
  display: flex;
  gap: 8px;
  color: #474d59;
  font-weight: 500;
`;

export const StyledTypography = styled.span`
  color: #474d59;
  font-weight: 500;
  font-size: 16px;
`;

export const StyledUpdateIcon = styled(UpdateIcon)`
  & {
    color: #00b5ad;
    transition: color 0.3s;

    &:hover {
      color: #ffffff;
    }
  }
`;
