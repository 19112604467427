import { RouteProps } from 'react-router-dom';

import Users from 'pages/Users';

import SignIn from 'pages/SignIn';

import { RouteProps as CustomRouteProps } from './Route';

import Patients from 'pages/Patients';
import Patient from 'pages/Patient';
import Logs from 'pages/Logs';
import Log from 'pages/Log';
import NotFound from 'pages/NotFound';
import Settings from 'pages/Settings';

export interface AuthorityConfig {
  placeholder?: string;
}

export function validateIfRouteShouldBeBlocked(): boolean {
  return false;
}

const config = {
  login: {
    component: SignIn,
    path: '/login',
    isOpen: true,
  },

  UserRegister: {
    component: Users,
    path: '/',
  },

  Patients: {
    component: Patients,
    path: '/curadoria',
  },

  Patient: {
    component: Patient,
    path: '/curadoria/:patientSk',
  },

  Logs: {
    component: Logs,
    path: '/logs',
  },

  Log: {
    component: Log,
    path: '/log/:logName',
  },

  NotFound: {
    component: NotFound,
    path: '*',
  },

  Settings: {
    component: Settings,
    path: 'settings',
  },
};

export const appRoutes = config as Record<
  keyof typeof config,
  Omit<CustomRouteProps, 'isPrivate'> & {
    routerProps?: Omit<RouteProps, 'path' | 'element' | 'index'>;
    isOpen?: boolean;
    permanent?: boolean;
    path: string;
    pageKey?: string;
  }
>;
