import { useCallback } from 'react';

import { ToggleButton, ToggleButtonGroup } from 'components/Buttons';
import { alpha, styled } from 'components/Material';

interface ApproveRejectButtonGroupProps {
  children?: never;
  value: boolean | null;
  onChange: (state: boolean) => void;
}

const StyledToggle = styled(ToggleButton)(({ theme }) => ({
  '&': {
    padding: 7,
    borderRadius: '999px',
  },

  '&.Mui-selected': {
    '&:first-of-type': {
      borderColor: theme.palette.success.light,
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.light, 0.15),
    },

    '&:last-of-type': {
      borderColor: theme.palette.error.light,
      color: theme.palette.error.light,
      backgroundColor: alpha(theme.palette.error.light, 0.08),
    },
  },
}));

function ApproveRejectButtonGroup({ value, onChange }: ApproveRejectButtonGroupProps): JSX.Element {
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, value: string) => {
      if (!value) return;

      onChange(value === 'true' ? true : false);
    },
    [onChange],
  );

  return (
    <ToggleButtonGroup
      value={typeof value === 'boolean' ? `${value}` : null}
      exclusive
      onChange={handleChange}
    >
      <StyledToggle value="true">Aceitar</StyledToggle>
      <StyledToggle value="false">Rejeitar</StyledToggle>
    </ToggleButtonGroup>
  );
}

export default ApproveRejectButtonGroup;
