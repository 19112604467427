import Clock from 'components/Icons/Clock';
import Modal from 'components/Modal';
import { OriginOptions } from '../../helpers';
import { ButtonsContainer, StyledDivTracking } from 'pages/Patient/styles';
import { StyledButton, StyledButtonNoBackGroundGray } from 'components/Buttons/styled';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface UserModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
  sourceOfInformation: string;
  service: string;
  date: Date | null;
  onUpdateSourceOfInformation: (newSource: string) => void;
  onUpdateService: (newSource: string) => void;
  onUpdateDate: (newDate: Date | null) => void;
  setModalSaved: (newDate: boolean) => void;
  origin: string;
  sourceInformationDisabled: string;
  serviceDisabled: string;
  dateDisabled: Date | null;
}

const TrackingModal: React.FC<UserModalProps> = ({
  isModalOpen,
  closeModal,
  origin,
  sourceOfInformation,
  service,
  date,
  onUpdateSourceOfInformation,
  onUpdateService,
  onUpdateDate,
  setModalSaved,
  sourceInformationDisabled,
  serviceDisabled,
  dateDisabled,
}) => {
  const [localSourceOfInformation, setLocalSourceOfInformation] = useState(sourceOfInformation);

  const [localService, setLocalService] = useState(service);

  const [dateTracking, setDateTracking] = useState<Date | null>(date);

  useEffect(() => {
    if (isModalOpen) {
      setLocalSourceOfInformation(sourceOfInformation);

      setLocalService(service);

      setDateTracking(date);
    }
  }, [isModalOpen, sourceOfInformation, service, date]);

  const handleSave = (): void => {
    onUpdateSourceOfInformation(localSourceOfInformation);

    onUpdateService(localService);

    onUpdateDate(dateTracking);

    setModalSaved(true);

    closeModal();
  };

  return (
    <Modal
      title={
        <StyledDivTracking>
          <Clock color="#636973" size={25} /> <span>Rastreio</span>{' '}
        </StyledDivTracking>
      }
      isOpen={isModalOpen}
      close={closeModal}
    >
      <TextField
        label="Origem"
        size="small"
        onChange={(e) => {
          setLocalSourceOfInformation(e.target.value);
          onUpdateSourceOfInformation(e.target.value);
        }}
        value={localSourceOfInformation || OriginOptions[origin]}
        disabled={!!sourceInformationDisabled}
        InputProps={{
          style: {
            borderRadius: 7,
          },
        }}
      />

      <TextField
        label="Atendimento"
        size="small"
        onChange={(e) => {
          setLocalService(e.target.value);
          onUpdateService(e.target.value);
        }}
        disabled={!!serviceDisabled}
        value={localService}
        InputProps={{
          style: {
            borderRadius: 7,
          },
        }}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(newDate) => {
            if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
              setDateTracking(newDate);
            } else {
              setDateTracking(null);
            }
          }}
          value={dateTracking}
          disabled={!!dateDisabled}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  height: '44px',
                  borderRadius: '7px',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>

      <ButtonsContainer>
        <StyledButtonNoBackGroundGray
          variant="contained"
          sx={{ width: '100%' }}
          onClick={closeModal}
        >
          Cancelar
        </StyledButtonNoBackGroundGray>

        <StyledButton variant="contained" sx={{ width: '100%' }} onClick={handleSave}>
          Salvar
        </StyledButton>
      </ButtonsContainer>
    </Modal>
  );
};

export default TrackingModal;
