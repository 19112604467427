import { useMemo } from 'react';

import NormalTab from './Normal';
import FilledTab from './Filled';

type TabStyle = 'normal' | 'filled';

export interface TabsProps<Tab extends string = string> {
  children?: never;
  value: Tab;
  fullWidth?: boolean;
  onChange: (value: Tab) => void;
  tabs: Array<{
    getLabel: (t: any) => string | JSX.Element;
    id: string;
    render?: boolean;
  }>;
  style?: TabStyle;

  addHorizontalLineUnderneath?: boolean;

  size?: 'normal' | 'reduced';
}

const components: { [key in TabStyle]: typeof NormalTab } = {
  normal: NormalTab,
  filled: FilledTab,
};

function Tabs<Tab extends string = string>({
  value,
  onChange,
  tabs,
  fullWidth = true,
  style = 'normal',
  size = 'normal',
  addHorizontalLineUnderneath,
}: TabsProps<Tab>): JSX.Element {
  const tabsToDisplay = tabs.filter(({ render }) => render ?? true);

  const tab = tabsToDisplay.findIndex((tab) => tab.id === value);

  const Component = useMemo(() => components[style], [style]);

  return (
    <Component
      tab={tab}
      fullWidth={fullWidth}
      onChange={(value) => {
        onChange(tabsToDisplay.find((_, index) => index === value)?.id as Tab);
      }}
      tabs={tabsToDisplay}
      size={size}
      addHorizontalLineUnderneath={addHorizontalLineUnderneath}
    />
  );
}

export default Tabs;
