import { convertPixelToRem } from 'css-blocks-styled-components';
import styled from 'styled-components';

export const Content = styled.div`
  text-align: center;

  h2 {
    font-size: ${convertPixelToRem(22)};
    line-height: 1.4;
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`;
