/* eslint-disable prettier/prettier */
import { TableBody } from '@mui/material';
import {
  IconTextWrapper,
  SpanColor,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledExpandMoreIcon,
  StyledPersonIcon,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
} from '../../styles';
import { DataItem } from '../../types';
import { formatData } from 'pages/Patient/helpers';

interface DataItemRendererProps {
  item: DataItem;
  index: number;
  searchTerm: string;
  titleKey: string;
}

const DataItemRenderer: React.FC<DataItemRendererProps> = ({
  item,
  index,
  searchTerm,
  titleKey,
}) => {
  const isItemMatch = Object.entries(item).some(
    ([_, value]) =>
      value !== null && value?.toString().toLowerCase().includes(searchTerm.toLowerCase()),
  );

  if (!isItemMatch) return null;

  const data = item['Data Início'] ? formatData(item['Data Início']) : formatData(item['Data do atendimento']);

  return (
    <StyledAccordion key={index}>
      <StyledAccordionSummary expandIcon={<StyledExpandMoreIcon />}>
        <IconTextWrapper>
          <StyledPersonIcon />
          <StyledTypography>
            {`${titleKey} ${data ? ` - ${data}` : ''}`}
          </StyledTypography>
        </IconTextWrapper>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <StyledTable>
          <TableBody>
            {Object.entries(item).map(([key, value], keyIndex) => (
              <StyledTableRow key={keyIndex}>
                <StyledTableCell component="th" scope="row">
                  <SpanColor>{key}: </SpanColor>
                </StyledTableCell>

                <StyledTableCell>{value !== null ? value : 'NULL'}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default DataItemRenderer;
