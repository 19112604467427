import { Box, Skeleton, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { StyledTable, StyledTableCellHeader, StyledTableHead } from '../styles';

function SkeletonTable(): JSX.Element {
  const rows = Array.from({ length: 7 }, (_, index) => index + 1);

  return (
    <TableContainer>
      <StyledTable aria-label="simple table">
        <StyledTableHead>
          <TableRow>
            <StyledTableCellHeader>SK</StyledTableCellHeader>

            <StyledTableCellHeader>Unidade</StyledTableCellHeader>

            <StyledTableCellHeader>CID</StyledTableCellHeader>

            <StyledTableCellHeader>Óbito</StyledTableCellHeader>

            <StyledTableCellHeader>Total Curado</StyledTableCellHeader>

            <StyledTableCellHeader>Curar</StyledTableCellHeader>
          </TableRow>
        </StyledTableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow key={row}>
              <TableCell>
                <Skeleton variant="text" width={330} />
              </TableCell>

              <TableCell>
                <Skeleton variant="text" width={110} />
              </TableCell>

              <TableCell>
                <Skeleton variant="text" width={110} />
              </TableCell>

              <TableCell>
                <Skeleton variant="text" width={60} />
              </TableCell>

              <TableCell>
                <Box position="relative" display="inline-flex" sx={{ marginRight: '40px' }}>
                  <Skeleton variant="circular" width={50} height={50} />
                </Box>
              </TableCell>

              <TableCell>
                <Skeleton variant="text" width={40} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}

export default SkeletonTable;
