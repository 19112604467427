import { FC } from 'react';

import { SVG, IconProps } from './base';

const Add: FC<IconProps> = ({ className, size = 24 }) => {
  return (
    <SVG
      stroke="white"
      fill="none"
      $size={size}
      className={className}
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      height="1em"
      width="1em"
      color="white"
    >
      <line
        x1="7.22223"
        y1="11.5557"
        x2="15.7778"
        y2="11.5557"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="11.5555"
        y1="15.7778"
        x2="11.5555"
        y2="7.22228"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="11.5" cy="11.5" r="8.5" stroke="white" strokeWidth="2" />
    </SVG>
  );
};

export default Add;
