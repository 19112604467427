import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment, TextField } from '@mui/material';

type CustomInputProps = {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  size?: 'small' | 'medium';
};

function CustomTextField({
  placeholder,
  value,
  onChange,
  onClear,
  size = 'small',
}: CustomInputProps): JSX.Element {
  return (
    <TextField
      placeholder={placeholder}
      id="outlined-start-adornment"
      size={size}
      value={value}
      onChange={onChange}
      InputProps={{
        style: { borderRadius: '8px' },
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton aria-label="limpar busca" onClick={onClear} edge="end">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default CustomTextField;
