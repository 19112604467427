import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { options } from './helpers';
import { useAuth } from 'providers/Auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Drawer, DrawerHeader, HoverableListItemButton, StyledIconButton } from './styles';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Menu, MenuItem } from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HamburguerMenu from 'components/Icons/HamburguerMenu';
import { hasIntersection } from 'utils/hasIntersection';
import LogoOncoClinicasIcon from 'components/Icons/logoOncoClinicas';

export default function MiniDrawer({ children }: { children: React.ReactNode }): JSX.Element {
  const theme = useTheme();
  const { user } = useAuth();
  const [open, setOpen] = useState(true);

  const auth = useAuth();

  const location = useLocation();

  const currentOptionName =
    options.find((option) => option.path === location.pathname)?.name || null;

  const [selectedOption, setSelectedOption] = useState<string | null>(currentOptionName);

  const navigate = useNavigate();

  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  const handleClickSideMenuItem = (name: string, path: string): void => {
    setSelectedOption(name);
    navigate(path);
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  const filteredOptions = options.filter((option) =>
    hasIntersection(option.roles, user.data.roles),
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon sx={{ color: 'gray' }} />
          </IconButton>

          <LogoOncoClinicasIcon
            onClick={() => navigate('/curadoria')}
            style={{ cursor: 'pointer' }}
          />

          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar src="/static/images/avatar/1.jpg" />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem sx={{ opacity: '0.5' }}>
              <AccountCircleIcon sx={{ mr: 2, color: 'gray' }} />

              {user.data.name}
            </MenuItem>

            <MenuItem onClick={() => auth.signOut()}>
              <ExitToAppIcon sx={{ mr: 2, color: 'gray' }} />
              Sair
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ display: 'flex', justifyContent: 'flex-start', padding: '0 20px' }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <HamburguerMenu color={'white'} />}
          </IconButton>{' '}
          Menu
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: 'white',
            marginLeft: '30px',
            marginRight: '30px',
            display: open ? 'block' : 'none',
          }}
        />

        <List>
          {filteredOptions.map(({ name, path, icon: Icon }) => (
            <ListItem key={name} disablePadding onClick={() => handleClickSideMenuItem(name, path)}>
              <HoverableListItemButton isSelected={selectedOption === name}>
                <StyledIconButton
                  sx={{
                    minWidth: 0,
                    marginLeft: open ? '15px' : '5px',
                    marginRight: open ? '15px' : '5px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Icon />
                </StyledIconButton>

                <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
              </HoverableListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, padding: '0 0 0 5px' }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
