export const tabs = [
  {
    getLabel: () => '1° Fila',
    id: 'tab1',
    render: true,
  },
  {
    getLabel: () => '2° Fila',
    id: 'tab2',
    render: true,
  },
];
