/* eslint-disable import/namespace */
import { flex, getBoxWidthAndHeight } from 'css-blocks-styled-components';
import { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from 'components/Material';

const motion = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface RingProps {
  $size: number;
  $color: Exclude<keyof typeof colors, undefined>;
  $colorShade: keyof typeof colors[keyof typeof colors];
}

const DualRingSpinner = styled.div<RingProps>`
  display: inline-block;
  ${({ $size }) => getBoxWidthAndHeight($size)};

  ${flex.alignCenter}

  :after {
    content: ' ';
    display: block;
    ${({ $size }) => getBoxWidthAndHeight($size)};

    border-radius: 50%;
    border: ${({ $size, $color, $colorShade }) =>
      `${$size * 0.15}px solid ${colors[$color][$colorShade]}`};

    border-color: ${({ $color, $colorShade }) =>
      `${colors[$color][$colorShade]} transparent ${colors[$color][$colorShade]} transparent`};

    animation: 1.2s linear ${motion} infinite;
  }
`;

interface DualRingLoaderProps {
  size?: number;
  color?: Exclude<keyof typeof colors, undefined>;
  colorShade?: keyof typeof colors['blue'];
  className?: string;
}

const DualRing: FC<DualRingLoaderProps> = ({
  color = 'blue',
  colorShade = 500,
  size = 23,
  className,
}) => (
  <DualRingSpinner
    $colorShade={colorShade as RingProps['$colorShade']}
    className={className}
    $color={color}
    $size={size}
  />
);

const StyledDualRing = styled(DualRing)``;

export default StyledDualRing;
