import { FormEvent, useState, FC, useRef } from 'react';

import { FaUserAlt, FaLock } from 'react-icons/fa';

import { InputWithIcon } from 'components/Inputs';
import FormWrapper from './FormWrapper';

import { useAuth } from '../../providers/Auth';

import { REGEX } from '../../utils/constants';

const SignInForm: FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();

  const handleLogIn = async (formEvent: FormEvent): Promise<void> => {
    formEvent.preventDefault();

    const [email, password] = [emailRef, passwordRef].map((ref) => ref.current?.value || '');

    const invalidState = [!email || !password, loading, !REGEX.EMAIL.test(email)].some(Boolean);

    if (invalidState) return;

    setLoading(true);

    const success = await signIn(email, password);

    if (!success) setLoading(false);
  };

  return (
    <FormWrapper loading={loading} onSubmit={handleLogIn} buttonText="Entrar">
      <InputWithIcon
        icon={FaUserAlt}
        name="email"
        placeholder="email"
        inputRef={emailRef}
        required
      />

      <InputWithIcon
        icon={FaLock}
        name="password"
        placeholder="Senha"
        inputRef={passwordRef}
        type="password"
        required
      />
    </FormWrapper>
  );
};

export default SignInForm;
