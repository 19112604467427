import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { ListItemButton } from 'components/Material';
import { IconButton, ListItem } from '@mui/material';

const drawerWidth = 280;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  background: theme.palette.primary.main,
  color: theme.palette.grey[100],
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: 'none',
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: theme.palette.primary.main,
  color: theme.palette.grey[100],
  overflowX: 'hidden',
  border: 'none',
  width: `calc(${theme.spacing(7)} + 7px)`,
  '@media (max-width:600px)': {
    display: 'none',
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: 'white',
  boxShadow: '0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[100],
  padding: 0,
}));

export const HoverableListItem = styled(ListItem)<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(255, 255, 255, 0.3)' : 'transparent'};
  margin: 2px 0;
  padding: 5px;
  border-radius: 10px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export const HoverableListItemButton = styled(ListItemButton)<{ isSelected: boolean }>`
  border-radius: 10px;

  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(255, 255, 255, 0.3)' : 'transparent'};

  margin: 1px 5px;

  padding: 10px 10px;

  width: calc(100% - 10px);

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;
