import { grey } from 'components/Material';
import { Row } from 'components/Containers';
import FullHeightContainer from 'components/FullHeightContainer';
import { getPadding, getScrollbarStyle } from 'css-blocks-styled-components';
import styled from 'styled-components';
import { QUERIES } from 'utils/constants';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppContainer = styled.div`
  height: 100%;
  flex: 1;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  ${getPadding(20, 16)};

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  isolation: isolate;
  height: 100%;

  ${getScrollbarStyle({
    scrollColor: grey[500],
    backgroundColor: '#ffffff',
  })}
`;

export const StyledRow = styled(Row)`
  height: 100%;
`;

export const StyledFullHeightWrapper = styled(FullHeightContainer)`
  width: 100%;

  ${QUERIES.SMALL_TABLET} {
    width: calc(100% - 16px);
  }
`;
