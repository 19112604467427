/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Pagination, TableBody, TableContainer, TableRow } from 'components/Material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DescriptionIcon from '@mui/icons-material/Description';

import {
  ButtonAndSearchContainer,
  Container,
  Count,
  HeaderPaper,
  PaginationContainer,
  PaperContainer,
  StyledDivCount,
  StyledTable,
  StyledTableCell,
  StyledTableCellHeader,
  StyledTableHead,
} from './styles';
import { useEffect, useMemo, useState } from 'react';
import apiRoutes from 'services/apiRoutes';
import { CircularProgress, PaginationItem } from '@mui/material';
import { StyledButtonNoBackGround } from 'components/Buttons/styled';
import { Link } from 'react-router-dom';
import SkeletonTableLogs from './components/Skeleton';
import { LogData, LogItem } from './types';
import Select from 'components/Select';
import { LogFilter } from 'types/logFilter';
import { Button } from 'components/Buttons';

const ITEMS_PER_PAGE = 10;

function Logs(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<LogFilter>();
  // const [filtersLoading, setFiltersLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const [paginatedLogs, setPaginatedLogs] = useState<LogData>();

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedEvents, setSelectedEvents] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<string>('');

  const handleChangeSelectedEvents = (value: string): void => {
    setSelectedEvents(value);
  };

  const handleChangeSelectedUsers = (value: string): void => {
    setSelectedUsers(value);
  };

  const fetchLogs = async (): Promise<void> => {
    setLoading(true);

    const response = await apiRoutes.fetchPaginatedLogs(
      {
        page,
        itemsPerPage: ITEMS_PER_PAGE,
      },
      {
        event: selectedEvents,
        email: selectedUsers,
      },
    );

    setPaginatedLogs(response);

    setLoading(false);
  };

  useEffect(() => {
    fetchLogs();
  }, [page]);

  useEffect(() => {
    const fetchUsers = async (): Promise<void> => {
      const response = await apiRoutes.fetchLogFilters();
      setFilters({
        events: response.events,
        users: response.users,
      });
    };

    fetchUsers();
  }, []);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const filteredLogs = useMemo(() => {
    return paginatedLogs?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedLogs, searchTerm, selectedEvents, selectedUsers]);

  return (
    <Container>
      <StyledDivCount>
        <h2>Logs</h2> <Count>{paginatedLogs?.total.toLocaleString()}</Count>
      </StyledDivCount>

      <PaperContainer>
        <HeaderPaper>
          <ButtonAndSearchContainer>
            {filters && filters?.events && filters?.users ? (
              <>
                <Select
                  className="react-select"
                  placeholder="Eventos"
                  id="events-autocomplete"
                  value={selectedEvents}
                  options={
                    filters?.events?.map((event) => ({
                      label: event.name,
                      value: event.tagName,
                    })) || []
                  }
                  propConfig={{ unique: 'value', label: 'label' }}
                  onChange={(e) => {
                    handleChangeSelectedEvents(e);
                  }}
                />

                <Select
                  className="react-select"
                  placeholder="Usuários"
                  id="users-autocomplete"
                  value={selectedUsers}
                  options={
                    filters?.users?.map((user) => ({
                      label: user.name,
                      value: user.email,
                    })) || []
                  }
                  propConfig={{ unique: 'value', label: 'label' }}
                  onChange={(e) => {
                    handleChangeSelectedUsers(e);
                  }}
                />
              </>
            ) : (
              <>
                <CircularProgress />
                <CircularProgress />
              </>
            )}

            <Button variant="contained" onClick={fetchLogs}>
              Buscar
            </Button>

            {/* <TextField
              placeholder="Buscar Log"
              id="outlined-start-adornment"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '7px',
                  height: '45px',
                },
                width: '100%',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            /> */}
          </ButtonAndSearchContainer>
        </HeaderPaper>

        {loading ? (
          <SkeletonTableLogs />
        ) : (
          <>
            <TableContainer>
              <StyledTable>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCellHeader>Eventos</StyledTableCellHeader>

                    <StyledTableCellHeader>Usuário</StyledTableCellHeader>

                    <StyledTableCellHeader>E-mail</StyledTableCellHeader>

                    <StyledTableCellHeader>Data</StyledTableCellHeader>

                    <StyledTableCellHeader></StyledTableCellHeader>
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {filteredLogs?.map((item: LogItem) => (
                    <TableRow key={item.log}>
                      <StyledTableCell>{item.event}</StyledTableCell>

                      <StyledTableCell>{item.name}</StyledTableCell>

                      <StyledTableCell>{item.email}</StyledTableCell>

                      <StyledTableCell>
                        <Box position="relative" display="inline-flex">
                          {item.date}
                        </Box>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <Link to={`/log/${item.log}`}>
                          <StyledButtonNoBackGround
                            variant="contained"
                            endIcon={<DescriptionIcon style={{ fontSize: '25px' }} />}
                          >
                            Ver Log
                          </StyledButtonNoBackGround>
                        </Link>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>

            <PaginationContainer>
              <Pagination
                count={paginatedLogs?.numberOfPages}
                shape="rounded"
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => {
                  if (item.type === 'previous') {
                    return (
                      <PaginationItem
                        {...item}
                        sx={{ border: '1px solid #CED3D9' }}
                        component={ArrowBackIcon}
                      />
                    );
                  }
                  if (item.type === 'next') {
                    return (
                      <PaginationItem
                        {...item}
                        sx={{ border: '1px solid #CED3D9' }}
                        component={ArrowForwardIcon}
                      />
                    );
                  }

                  return <PaginationItem sx={{ color: '#7B868C' }} {...item} />;
                }}
              />
            </PaginationContainer>
          </>
        )}
      </PaperContainer>
    </Container>
  );
}

export default Logs;
