import { Box, Chip, TextField } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from 'styled-components';

export const PatientInformations = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  font-size: 14px;
  color: #474d59;

  > div {
    flex: 1 1 30%;
  }
`;

export const UserSummary = styled.div`
  display: flex;
  border: 1px solid #00b5ad;
  padding: 25px 15px;
  border-radius: 6px;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 600;
    color: #00b5ad;
  }
`;

export const StyledChip = styled(Chip)`
  && {
    background-color: #00b5ad;
    color: white;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 5px;
`;

export const StyledTextField = styled(TextField)`
  width: 70%;
  margin-right: 5px;

  & .MuiOutlinedInput-notchedOutline {
    border-color: #00b5ad;
  }
`;

export const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  color: gray;
`;

export const StyledTextFieldRedBorder = styled(TextField)`
  width: 70%;

  & .MuiOutlinedInput-notchedOutline {
    border-color: #ffb5b5;
  }
`;

export const StyledSearchTextField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-root {
    border-radius: 7px;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 500px;
`;
