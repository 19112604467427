const LogoOncoClinicasIcon = (props: any): JSX.Element => {
  return (
    <svg
      width="201"
      height="25"
      viewBox="0 0 201 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6825_1146)">
        <path
          d="M200.989 6.88142V4.32275C200.989 4.25053 200.979 4.18862 200.948 4.12672C200.917 4.06482 200.886 4.00291 200.834 3.96164C200.783 3.91006 200.721 3.87911 200.659 3.84815C200.597 3.8172 200.525 3.80688 200.452 3.80688H199.276C196.79 3.82752 195.397 4.37433 194.479 5.47827C193.282 6.90205 192.89 9.04803 194.334 12.3289C195.531 15.063 195.232 16.2082 194.592 16.8891C193.922 17.601 192.488 17.8177 191.157 17.8177H190.682C190.538 17.8177 190.403 17.8692 190.3 17.9724C190.197 18.0653 190.146 18.1994 190.146 18.3335V20.8922H191.91C194.499 20.9128 196.625 20.0152 197.667 18.9113C199.452 17.0439 198.967 14.7535 197.904 12.3702C196.728 9.71865 196.79 8.45995 197.429 7.70679C197.904 7.1703 199.049 6.8711 201.01 6.90205"
          fill="#A7A9AC"
        />
        <path
          d="M182.357 3.82738C181.521 3.80674 180.685 3.95118 179.911 4.25038C179.138 4.53926 178.426 4.9829 177.827 5.54003C177.25 6.1178 176.796 6.78842 176.496 7.53126C176.197 8.2741 176.053 9.06852 176.063 9.86295V20.3349C176.063 20.4897 176.125 20.6341 176.238 20.7476C176.352 20.8611 176.507 20.9127 176.672 20.9127H179.385V16.2493H185.39V20.3349C185.39 20.4897 185.452 20.6341 185.565 20.7373C185.679 20.8405 185.823 20.9127 185.988 20.9127H188.702V9.86295C188.722 9.06852 188.578 8.2741 188.279 7.53126C187.979 6.78842 187.525 6.10748 186.948 5.54003C185.792 4.43609 184.183 3.83769 182.367 3.83769L182.357 3.82738ZM185.379 13.1954H179.365V9.77009C179.365 7.99553 180.499 6.91222 182.357 6.91222C184.214 6.91222 185.379 7.99553 185.379 9.77009V13.1954Z"
          fill="#A7A9AC"
        />
        <path
          d="M166.953 6.66457C167.778 6.66457 168.583 6.87092 169.295 7.26297C170.007 7.65503 170.595 8.22247 171.007 8.89309C171.069 9.00658 171.162 9.08912 171.276 9.15102C171.389 9.21292 171.513 9.25419 171.647 9.25419H174.753C173.69 5.9011 170.822 3.79639 166.942 3.79639C162.279 3.79639 158.647 7.55185 158.647 12.3494C158.647 17.1469 162.279 20.9023 166.942 20.9023C170.75 20.9023 173.638 18.746 174.732 15.4239H171.668C171.534 15.4239 171.41 15.4652 171.296 15.5271C171.183 15.589 171.09 15.6818 171.028 15.785C170.615 16.4659 170.027 17.0437 169.315 17.4461C168.604 17.8484 167.799 18.0651 166.973 18.0754C164.157 18.0754 161.939 15.4755 161.939 12.3597C161.939 9.24388 164.157 6.66457 166.973 6.66457"
          fill="#A7A9AC"
        />
        <path
          d="M153.902 20.8922H156.605V4.38465C156.605 4.22989 156.543 4.08545 156.429 3.97196C156.316 3.85847 156.161 3.80688 155.996 3.80688H153.293V20.3144C153.293 20.4692 153.355 20.6136 153.468 20.7271C153.582 20.8406 153.737 20.9025 153.902 20.9025"
          fill="#A7A9AC"
        />
        <path
          d="M144.915 3.82738C144.079 3.80674 143.244 3.95118 142.47 4.25038C141.696 4.53926 140.984 4.9829 140.386 5.54003C139.808 6.1178 139.354 6.78842 139.055 7.53126C138.756 8.2741 138.611 9.06852 138.622 9.86295V20.3349C138.622 20.4071 138.632 20.4897 138.663 20.5619C138.694 20.6341 138.735 20.696 138.797 20.7476C138.849 20.7992 138.921 20.8405 138.993 20.8714C139.065 20.9024 139.148 20.9127 139.23 20.9127H141.933V9.79073C141.933 9.0582 142.212 8.35663 142.738 7.82014C143.264 7.28364 143.976 6.97413 144.74 6.93286C146.741 6.86064 147.938 7.96458 147.938 9.79073V20.3349C147.938 20.4897 148 20.6341 148.113 20.7476C148.227 20.8611 148.382 20.9127 148.547 20.9127H151.26V9.86295C151.281 9.06852 151.136 8.2741 150.837 7.53126C150.538 6.78842 150.084 6.10748 149.506 5.54003C148.351 4.43609 146.741 3.83769 144.925 3.83769"
          fill="#A7A9AC"
        />
        <path
          d="M133.866 20.8921H136.579V8.14002C136.579 8.0678 136.569 7.98526 136.538 7.91304C136.507 7.84082 136.465 7.77892 136.404 7.72733C136.352 7.67575 136.28 7.63448 136.208 7.60352C136.135 7.57257 136.053 7.56226 135.97 7.56226H133.257V20.3143C133.257 20.4691 133.319 20.6135 133.432 20.727C133.546 20.8405 133.7 20.9024 133.866 20.9024"
          fill="#A7A9AC"
        />
        <path
          d="M133.257 6.70589C133.288 6.02495 133.566 3.86865 136.569 3.86865V6.11781C136.569 6.27256 136.507 6.417 136.393 6.53049C136.28 6.64398 136.125 6.70589 135.97 6.70589H133.257Z"
          fill="#A7A9AC"
        />
        <path
          d="M123.785 15.5995V4.42591C123.785 4.35369 123.775 4.27115 123.744 4.19893C123.713 4.12671 123.672 4.06481 123.61 4.01322C123.558 3.96163 123.486 3.92036 123.414 3.88941C123.342 3.85846 123.259 3.84814 123.177 3.84814H120.474V15.651C120.474 18.8494 122.382 20.9025 125.952 20.9025H131.234V18.3748C131.234 18.22 131.173 18.0756 131.059 17.9621C130.946 17.8486 130.791 17.7867 130.626 17.7867H125.983C124.404 17.7867 123.796 17.1367 123.796 15.5995H123.785Z"
          fill="#A7A9AC"
        />
        <path
          d="M111.25 3.89966C110.198 3.89966 109.156 4.106 108.176 4.47742C109.259 4.99328 110.177 5.79803 110.796 6.78848C110.951 6.75753 111.096 6.74721 111.25 6.73689C112.065 6.73689 112.87 6.93292 113.572 7.32497C114.273 7.71703 114.861 8.27416 115.274 8.94478C115.336 9.04795 115.429 9.1408 115.542 9.20271C115.656 9.26461 115.78 9.29556 115.914 9.30588H118.978C117.915 5.99405 115.078 3.90998 111.261 3.90998L111.25 3.89966Z"
          fill="#A7A9AC"
        />
        <path
          d="M111.275 18.2139C108.468 18.2139 106.25 15.6243 106.25 12.5085C106.25 11.3633 106.591 10.2284 107.22 9.25859C106.487 8.49512 105.631 7.86577 104.672 7.38086C103.578 8.88717 102.99 10.6824 103 12.5188C103 17.2957 106.621 21.0409 111.275 21.0409C115.061 21.0409 117.95 18.8949 119.033 15.583H115.979C115.845 15.583 115.721 15.6243 115.608 15.6862C115.494 15.7481 115.401 15.841 115.34 15.9441C114.927 16.6251 114.339 17.2028 113.627 17.6052C112.915 18.0076 112.11 18.2243 111.285 18.2346"
          fill="#A7A9AC"
        />
        <path
          d="M103.006 3.89966C98.3221 3.89966 94.5254 7.64481 94.5254 12.401C94.5254 17.1573 98.3221 20.9334 103.006 20.9334C103.966 20.9334 104.905 20.7683 105.802 20.4485C105.121 19.9945 104.275 19.3858 103.883 18.9215C103.594 18.9731 103.305 18.9937 103.006 18.9937C99.4776 18.9937 96.5991 16.1152 96.5991 12.401C96.5991 8.68685 99.4776 5.79803 103.006 5.79803C106.535 5.79803 109.413 8.62494 109.413 12.401C109.413 13.5463 109.114 14.6811 108.546 15.6922C109.062 16.1256 109.64 16.497 110.269 16.7859C111.074 15.4549 111.497 13.9383 111.497 12.401C111.497 7.55195 107.773 3.89966 103.016 3.89966"
          fill="#00B5AD"
        />
        <path
          d="M85.374 5.71539C87.9843 5.71539 89.8414 7.01536 90.8421 8.86214C90.904 8.98595 90.9969 9.0788 91.1207 9.15102C91.2342 9.22324 91.3786 9.26451 91.5127 9.26451H93.246C92.1524 6.0249 89.2842 3.79639 85.374 3.79639C80.6694 3.79639 76.8623 7.62407 76.8623 12.339C76.8623 17.054 80.6797 20.9023 85.374 20.9023C89.0573 20.9023 92.0802 18.746 93.2564 15.4239H91.5127C91.3786 15.4239 91.2445 15.4652 91.131 15.5374C91.0175 15.6096 90.9247 15.7025 90.8628 15.8263C90.3469 16.7858 89.5525 17.5802 88.593 18.1373C87.6232 18.6944 86.5192 18.9833 85.3946 18.973C81.8558 18.973 78.9567 15.9707 78.9567 12.3494C78.9567 8.72802 81.8558 5.72571 85.3946 5.72571"
          fill="#00B5AD"
        />
        <path
          d="M68.7116 3.80677C65.2038 3.77582 62.4697 6.31385 62.4697 9.65663V20.3556C62.4697 20.5103 62.5316 20.6548 62.6451 20.7683C62.7586 20.8714 62.9134 20.9333 63.0681 20.9333H64.6054V9.65663C64.5951 9.14077 64.6879 8.63522 64.884 8.15032C65.08 7.67572 65.3792 7.23208 65.7506 6.87098C66.122 6.49956 66.576 6.21068 67.0712 6.01465C67.5664 5.81862 68.1029 5.71545 68.6394 5.71545C69.1759 5.71545 69.7124 5.81862 70.2076 6.01465C70.7029 6.21068 71.1568 6.50988 71.5282 6.87098C71.8997 7.2424 72.1989 7.67572 72.3949 8.15032C72.5909 8.62491 72.6838 9.14077 72.6735 9.65663V20.3556C72.6735 20.4278 72.6941 20.5103 72.725 20.5722C72.756 20.6445 72.7973 20.7064 72.8592 20.758C72.9108 20.8095 72.983 20.8508 73.0552 20.8818C73.1274 20.9127 73.21 20.923 73.2925 20.923H74.8298V9.87329C74.8298 6.60273 72.137 3.81708 68.722 3.78613"
          fill="#00B5AD"
        />
        <path
          d="M51.9156 3.80688C47.2109 3.80688 43.3936 7.57267 43.3936 12.3599C43.3936 17.147 47.2109 20.9335 51.9156 20.9335C56.6202 20.9335 60.4376 17.1367 60.4376 12.3599C60.4376 7.58298 56.6924 3.80688 51.9156 3.80688ZM51.9156 18.9938C48.3665 18.9938 45.4673 16.105 45.4673 12.3599C45.4673 8.61471 48.3665 5.72589 51.9156 5.72589C55.4647 5.72589 58.3638 8.56312 58.3638 12.3599C58.3638 16.1566 55.4647 18.9938 51.9156 18.9938Z"
          fill="#00B5AD"
        />
        <path
          d="M24.3899 20.6345C20.3146 20.6345 17.0853 16.8687 17.0853 12.3498C17.0853 10.6681 17.5805 9.02765 18.4884 7.60387C17.4258 6.48961 16.1774 5.57138 14.7742 4.88013C13.1854 7.07769 12.3394 9.67763 12.3497 12.3498C12.3497 19.2933 17.6012 24.7408 24.3796 24.7408C29.889 24.7408 34.0778 21.625 35.6563 16.7965H31.1993C31.0135 16.7965 30.8278 16.8481 30.6628 16.941C30.4977 17.0338 30.3636 17.1679 30.2707 17.3227C29.0739 19.2107 26.9692 20.6242 24.3796 20.6242L24.3899 20.6345Z"
          fill="#A7A9AC"
        />
        <path
          d="M24.2966 0C22.7593 0.0103172 21.2427 0.299199 19.8086 0.835694C21.3871 1.58885 22.718 2.7547 23.626 4.18879C23.8426 4.14752 24.0593 4.11657 24.2759 4.10625C25.4624 4.10625 26.618 4.39513 27.6497 4.96258C28.6711 5.53003 29.5274 6.33477 30.1258 7.31491C30.2187 7.46966 30.3528 7.60379 30.5179 7.69664C30.6829 7.7895 30.8583 7.84108 31.0544 7.84108H35.5114C33.9948 3.02294 29.8679 0 24.2966 0Z"
          fill="#A7A9AC"
        />
        <path
          d="M12.3188 0C5.51971 0 0 5.43717 0 12.36C0 19.2829 5.51971 24.7613 12.3188 24.7613C13.7013 24.7613 15.0838 24.524 16.3734 24.0597C15.383 23.4098 14.1552 22.5122 13.5775 21.8415C13.1545 21.9138 12.7314 21.9447 12.3084 21.9447C7.1911 21.9447 3.00231 17.7662 3.00231 12.36C3.00231 6.9538 7.1911 2.77533 12.3188 2.77533C17.4464 2.77533 21.6249 6.88158 21.6249 12.36C21.6249 14.0314 21.1916 15.6822 20.3662 17.1575C21.1193 17.7972 21.955 18.3337 22.8629 18.7567C24.0288 16.8171 24.6375 14.6195 24.6272 12.3807C24.6375 5.30305 19.2313 0 12.3188 0Z"
          fill="#00B5AD"
        />
      </g>
      <defs>
        <clipPath id="clip0_6825_1146">
          <rect width="201" height="24.7613" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoOncoClinicasIcon;
