import { SyntheticEvent } from 'react';

import { styled, MuiTabs, Tab } from 'components/Material';

import { CustomTabsProps } from './types';

interface StyledTabProps {
  label: string | JSX.Element;
  size?: 'normal' | 'reduced';
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  fullWidth?: boolean;
  onChange: (event: SyntheticEvent, newValue: number) => void;
  size?: 'normal' | 'reduced';
  addHorizontalLineUnderneath?: boolean;
}

const Wrapper = styled(
  ({ fullWidth: _, addHorizontalLineUnderneath: __, ...props }: StyledTabsProps) => (
    <MuiTabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ),
)(({ theme, size, fullWidth, addHorizontalLineUnderneath }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    bottom: 5,
  },

  '& .MuiTabs-scroller': {
    ...(addHorizontalLineUnderneath
      ? {
          '&:before': {
            content: "''",
            width: '100%',
            height: '2px',
            background: '#eee',
            position: 'absolute',
            bottom: '3px',
            left: 0,
          },
        }
      : {}),

    overflowX: 'auto !important',

    scrollbarColor: '#eee #fff',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      border: '1px solid #eee',
      backgroundColor: '#eee',
    },

    '&::-webkit-scrollbar-track': {
      background: '#fff',
    },
  },

  minHeight: size === 'reduced' ? '36px' : undefined,

  ...(fullWidth ? { width: '100%' } : {}),

  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    height: size === 'reduced' ? '2px' : '5px',
    borderRadius: '999px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const NormalTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme, size }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: '#474D59',

    '&.Mui-selected': {
      color: '#00B5AD !important',
    },

    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.main,
    },

    '&.MuiButtonBase-root': {
      color: '#474D59',
      fontWeight: '500',
      padding: size === 'reduced' ? '4px' : '8px 12px',
      minHeight: size === 'reduced' ? '36px' : undefined,
      minWidth: size === 'reduced' ? '50px' : undefined,
    },
  }),
);

function FilledTabs({
  onChange,
  tabs,
  tab,
  size,
  fullWidth,
  addHorizontalLineUnderneath,
}: CustomTabsProps): JSX.Element {
  return (
    <Wrapper
      addHorizontalLineUnderneath={addHorizontalLineUnderneath}
      fullWidth={fullWidth}
      value={tab}
      onChange={(_, value) => onChange(value)}
      size={size}
    >
      {tabs.map(({ getLabel, id }) => (
        <NormalTab key={id} label={getLabel('')} size={size} />
      ))}
    </Wrapper>
  );
}

export default FilledTabs;
