import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

import ConfirmationModal, { ConfirmationModalProps } from 'components/ConfirmationModal';

export type RequestConfirmationParams = Omit<ConfirmationModalProps, 'cancel'> &
  Partial<Pick<ConfirmationModalProps, 'cancel'>>;

interface ToastContextData {
  requestConfirmation(message: RequestConfirmationParams): void;
}

const ConfirmationContext = createContext<ToastContextData>({} as ToastContextData);

function ConfirmationProvider({ children }: { children: ReactNode }): JSX.Element {
  const [confirmationConfig, setConfirmationConfig] = useState<RequestConfirmationParams | null>(
    null,
  );

  const requestConfirmation = useCallback((config: RequestConfirmationParams) => {
    setConfirmationConfig({
      ...config,
      onConfirm: async () => {
        await config.onConfirm();
        setConfirmationConfig(null);
      },
    });
  }, []);

  const cancelConfirmation = useCallback(() => {
    confirmationConfig?.cancel?.();
    setConfirmationConfig(null);
  }, [confirmationConfig]);

  const data = useMemo(
    () => ({
      requestConfirmation,
    }),
    [requestConfirmation],
  );

  return (
    <ConfirmationContext.Provider value={data}>
      <ConfirmationModal
        {...((confirmationConfig || {}) as ConfirmationModalProps)}
        cancel={cancelConfirmation}
      />

      {children}
    </ConfirmationContext.Provider>
  );
}

function useConfirmation(): ToastContextData {
  const context = useContext(ConfirmationContext);

  if (!context.requestConfirmation) {
    throw new Error('useToast must be used within a ConfirmationProvider');
  }

  return context;
}

export { ConfirmationProvider, useConfirmation };
