import { IconButton } from 'components/Buttons';
import { FormEvent, useState, FC, useRef } from 'react';

import { FaUserAlt } from 'react-icons/fa';

import { InputWithIcon } from 'components/Inputs';
import { BackArrow } from 'components/Icons';

import FormWrapper from './FormWrapper';

import { useAuth } from 'providers/Auth';

import { REGEX } from 'utils/constants';

import { BackButtonAnchor } from './styles';

const ForgotPassword: FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const { triggerForgotPasswordFlow, navigateToLogin } = useAuth();

  const handleLogIn = async (formEvent: FormEvent): Promise<void> => {
    formEvent.preventDefault();

    const email = (emailRef.current?.value || '').trim();

    const invalidState = [!email, loading, !REGEX.EMAIL.test(email)].some(Boolean);

    if (invalidState) return;

    setLoading(true);

    const success = await triggerForgotPasswordFlow(email);

    if (!success) setLoading(false);
  };

  return (
    <FormWrapper loading={loading} onSubmit={handleLogIn} buttonText="Recuperar senha">
      <BackButtonAnchor>
        <IconButton onClick={navigateToLogin}>
          <BackArrow />
        </IconButton>
      </BackButtonAnchor>

      <InputWithIcon
        icon={FaUserAlt}
        name="email"
        placeholder="email"
        inputRef={emailRef}
        required
      />
    </FormWrapper>
  );
};

export default ForgotPassword;
