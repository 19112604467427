/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useCallback, useMemo, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ReactSelect from 'react-select';
import { Skeleton } from 'components/Material';

import { Column, Row } from 'components/Containers';
import Label from 'components/Label';

import { getBaseReactSelectStyle } from './styles';

import { ReactSelectOption, SelectValue } from './types';

interface BaseSelectProps<Entity extends Record<string, any>> {
  id: string;

  options: Entity[];

  propConfig: {
    unique: keyof Entity;
    label: keyof Entity;
  };

  isSearchable?: boolean;
  disabled?: boolean;
  isMulti?: boolean;
  labelGap?: number;
  width?: string | number;
  minHeight?: string | number;
  label?: string;
  labelPosition?: 'left' | 'top';
  className?: string;
  loading?: boolean;
  placeholder?: string;
  hasError?: boolean;
  getStyledOptionLabel?: (props: Entity) => JSX.Element;
}

type SelectValueOptions =
  | { isMulti: true; value: string[]; onChange: (values: string[]) => void }
  | { isMulti?: false; value: string; onChange: (values: string) => void };

type SelectProps<Entity extends Record<string, any>> = BaseSelectProps<Entity> & SelectValueOptions;

function Select<Entity extends Record<string, any>>({
  id,
  options,
  propConfig,
  value,
  minHeight = 45,
  isSearchable = true,
  isMulti = false,
  width = '100%',
  label,
  disabled,
  className,
  onChange,
  labelPosition = 'left',
  loading,
  placeholder,
  hasError = false,
  labelGap = 8,
  getStyledOptionLabel,
}: SelectProps<Entity>): JSX.Element {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const baseStyle = getBaseReactSelectStyle({ width, hasError, minHeight, disabled });

  const isRow = labelPosition === 'left';

  const Container = isRow ? Row : Column;

  const reactSelectOptions = useMemo(() => {
    const { unique, label } = propConfig;

    return options.map((entity) => ({
      value: entity[unique],
      label: entity[label],
    })) as ReactSelectOption[];
  }, [options, propConfig]);

  const reactSelectValue = isMulti
    ? reactSelectOptions.filter((option) => value.includes(option.value as string))
    : reactSelectOptions.find((option) => option.value === value);

  const handleChange = useCallback(
    (value: SelectValue) => {
      if (!value) return onChange((isMulti ? [] : '') as string[] & string);

      const updatedValue = Array.isArray(value)
        ? value.map((option) => option.value)
        : (value as ReactSelectOption).value;

      onChange(updatedValue as string[] & string);
    },
    [onChange, isMulti],
  );

  const getOptionLabel = ({ value, label }: ReactSelectOption): ReactNode => {
    const selectedEntity = options.find((option) => option[propConfig.unique] === value);

    const isSelected = Array.isArray(reactSelectValue)
      ? reactSelectValue.some((selectedOption) => selectedOption.value === value)
      : reactSelectValue?.value === value;

    const styledLabel =
      getStyledOptionLabel && selectedEntity ? getStyledOptionLabel(selectedEntity) : label;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {styledLabel}
        {isSelected && dropdownOpen && <CheckIcon sx={{ color: '#00B5AD' }} />}
      </div>
    );
  };

  return (
    <Container
      className={className}
      gap={labelGap}
      justifyContent={isRow ? 'space-between' : undefined}
      fullWidth={!isRow}
    >
      {label && <Label htmlFor={id}>{label}</Label>}

      {loading ? (
        <Skeleton variant="rounded" width="100%" height={42} />
      ) : (
        <ReactSelect
          onMenuOpen={() => setDropdownOpen(true)}
          onMenuClose={() => setDropdownOpen(false)}
          isDisabled={disabled}
          inputId={id}
          options={reactSelectOptions}
          formatOptionLabel={getOptionLabel}
          value={reactSelectValue}
          isClearable={true}
          onChange={handleChange}
          menuPlacement="auto"
          menuPosition="fixed"
          isSearchable={isSearchable}
          styles={baseStyle}
          isMulti={isMulti}
          placeholder={placeholder}
        />
      )}
    </Container>
  );
}

export default Select;
