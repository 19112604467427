/* eslint no-console: "off" */
import { createContext, useCallback, useState, useContext, useMemo, ReactNode } from 'react';

import { keys as localStorageKeys } from '../config/localStorage';

import { useToast } from './Toast';

import api, { unauthedApi } from 'services/api';

import { getSavedUserInfo } from '../utils/auth';

import { AuthContextProps, AuthenticatedUser, tLoginAction } from 'types/auth';
import { LoggedUser } from 'types/entities';
import { AxiosError, AxiosResponse } from 'axios';

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const { addToast } = useToast();

  const [userData, setUserData] = useState<AuthenticatedUser>(getSavedUserInfo);

  const [loginAction, setLoginAction] = useState<tLoginAction>(() => {
    if (userData.token) return userData.data.changePassword ? 'change-password-after-login' : null;

    const sent = sessionStorage.getItem(localStorageKeys.codeSent);

    return sent ? 'forgot-password-sent' : 'login';
  });

  const signOut = useCallback(
    async ({ errorMessage }: { errorMessage?: string } = {}) => {
      if (errorMessage) addToast({ title: errorMessage });

      localStorage.removeItem(localStorageKeys.user);

      setLoginAction('login');
      setUserData({ data: {} } as AuthenticatedUser);
    },
    [addToast],
  );

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        localStorage.removeItem(localStorageKeys.user);

        setLoginAction('login');
        setUserData({ data: {} } as AuthenticatedUser);
      }

      return Promise.reject(error);
    },
  );

  const updateUserMainData = useCallback((user: Partial<LoggedUser>) => {
    setUserData((old) => {
      const updatedInfo = {
        ...old,
        data: {
          ...old.data,
          ...user,
        },
      };

      localStorage.setItem(localStorageKeys.user, JSON.stringify(updatedInfo));

      return updatedInfo;
    });
  }, []);

  const updateUserPicture = useCallback(
    (picture: string) => {
      updateUserMainData({ picture });
    },
    [updateUserMainData],
  );

  const signIn = useCallback(
    async (email: string, password: string) => {
      try {
        const {
          data: { token, accessValidity, user },
        } = await unauthedApi.post('/auth', { email, password });

        const stateData = {
          token,
          accessValidity,
          data: user,
        };

        setUserData(stateData);
        localStorage.setItem(localStorageKeys.user, JSON.stringify(stateData));

        setLoginAction(user.changePassword ? 'change-password-after-login' : null);

        // eslint-disable-next-line consistent-return
        return true;
      } catch (err) {
        addToast({
          title: 'Erro de login',
          type: 'error',
          description: 'Cheque as credenciais e sua conexão com a VPN',
        });
      }
    },
    [addToast],
  );

  const navigateToCodeInput = useCallback(() => {
    setLoginAction('change-password-with-code');
  }, []);

  const triggerForgotPasswordFlow = useCallback(
    async (email: string) => {
      try {
        await unauthedApi.post('/users/forgot-password', { email });

        setLoginAction('forgot-password-sent');
        sessionStorage.setItem(localStorageKeys.codeSent, email);

        return true;
      } catch (err) {
        addToast({
          title: 'Erro recuperando sua senha',
          type: 'error',
        });

        return false;
      }
    },
    [addToast],
  );

  const completeNewPasswordAfterLogin = useCallback(
    async (newPassword: string) => {
      try {
        await api.post('/users/change-password', { newPassword });

        updateUserMainData({ changePassword: false });
        setLoginAction(null);

        return true;
      } catch (err) {
        addToast({
          title: 'Erro ao trocar senha',
          type: 'error',
        });

        return false;
      }
    },
    [addToast, updateUserMainData],
  );

  const navigateToLogin = useCallback(() => {
    setLoginAction('login');
  }, []);

  const forgotPassword = useCallback(() => {
    setLoginAction('forgot-password');
  }, []);

  const submitLoginCode = useCallback(
    async (params: { email: string; password: string; code: string }) => {
      try {
        await unauthedApi.post('/users/complete-forgot-password', params);

        sessionStorage.removeItem(localStorageKeys.codeSent);

        setLoginAction('login');

        addToast({
          type: 'success',
          title: 'Senha recuperada!',
          description: 'Você pode logar agora',
        });

        return true;
      } catch (err) {
        addToast({
          title: 'Erro ao recuperar senha',
          type: 'error',
        });

        console.log(err);

        return false;
      }
    },
    [addToast],
  );

  const data = useMemo(
    () => ({
      user: userData,
      signIn,
      signOut,
      updateUserPicture,
      loginAction,
      navigateToCodeInput,
      submitLoginCode,
      forgotPassword,
      triggerForgotPasswordFlow,
      navigateToLogin,
      completeNewPasswordAfterLogin,
      updateUserMainData,
    }),
    [
      userData,
      loginAction,
      signIn,
      signOut,
      navigateToCodeInput,
      submitLoginCode,
      updateUserPicture,
      forgotPassword,
      triggerForgotPasswordFlow,
      navigateToLogin,
      completeNewPasswordAfterLogin,
      updateUserMainData,
    ],
  );

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}

function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);

  if (!context.user) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
