import { Component, ErrorInfo, ReactNode } from 'react';

import { Warning } from '../Icons';

import { ErrorContainer } from './styles';

interface ErrorProps {
  children: ReactNode;
}

interface ErrorState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): ErrorState {
    return { hasError: true };
  }

  public static componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // logErrorToMyService(error, errorInfo);
    console.log('ERROR!', error, errorInfo); // eslint-disable-line
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (!hasError) return children;

    return (
      <ErrorContainer>
        <div>
          <Warning />

          <h2>Um erro ocorreu</h2>

          <p>Estamos verificando</p>
        </div>
      </ErrorContainer>
    );
  }
}

export default ErrorBoundary;
