import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import environment from 'config/env';

import { addAuthTokenToRequest } from 'utils/auth';

const api = axios.create({
  baseURL: environment.url,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': import.meta.env.VITE_OCP_APIM_SUBSCRIPTION_KEY,
  },
});

export const unauthedApi = axios.create({
  baseURL: environment.url,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': import.meta.env.VITE_OCP_APIM_SUBSCRIPTION_KEY,
  },
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) =>
    addAuthTokenToRequest(config) as Promise<InternalAxiosRequestConfig>,
);

export default api;
