import {
  Auth,
  DomainItem,
  Origins,
  PaginatedResult,
  Patient,
  PatientCuratorshipDataInputProps,
  UpdatedDataUser,
  User,
} from 'types/entities';
import api from './api';
import { LogFilter } from 'types/logFilter';

const apiRoutes = {
  async login(loginCredentials: { email: string; password: string }): Promise<Auth> {
    return await api.post('/auth', loginCredentials);
  },

  async fetchPaginatedUsers(
    paginateOptions: {
      page: number;
      itemsPerPage: number;
    },
    search?: string,
  ): Promise<PaginatedResult<User>> {
    return (await api.post(`/user/find-all/${search}`, paginateOptions)).data;
  },

  async fetchPaginatedPatients(
    paginateOptions: {
      page: number;
      itemsPerPage: number;
    },
    filter?: Patient,
  ): Promise<PaginatedResult<Patient>> {
    return (
      await api.post(
        `/patient/find-all/first-queue/page/${paginateOptions.page}/items-per-page/${paginateOptions.itemsPerPage}`,
        { ...filter },
      )
    ).data;
  },

  async fetchPaginatedPatientsSecondQueue(
    paginateOptions: {
      page: number;
      itemsPerPage: number;
    },
    filter?: Patient,
  ): Promise<PaginatedResult<Patient>> {
    return (
      await api.post(
        `/patient/find-all/second-queue/page/${paginateOptions.page}/items-per-page/${paginateOptions.itemsPerPage}`,
        { ...filter },
      )
    ).data;
  },

  async fetchdPatientData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/data/${patientSk}`)).data;
  },

  async fetchTreatmentdData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/treatment/${patientSk}`)).data;
  },

  async fetchSystemicTreatmentdData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/systemic-treatment/${patientSk}`)).data;
  },

  async fetchGenomicsData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/genomics/${patientSk}`)).data;
  },

  async fetchPathologyData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/pathology/${patientSk}`)).data;
  },

  async fetchLaboratoryData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/labs-vital/${patientSk}`)).data;
  },

  async fetchOutcomeData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/outcome/${patientSk}`)).data;
  },

  async fetchComorbiditiesData(patientSk: string | undefined): Promise<Origins> {
    return (await api.get(`/patient/comorbidity/${patientSk}`)).data;
  },

  async fetchDomains(group: string): Promise<DomainItem[]> {
    return (await api.get(`/domain/group/${group}/domain-types`)).data;
  },

  async updateUser(userId: string, updatedData: UpdatedDataUser): Promise<User> {
    return (await api.put(`/user/update/${userId}`, updatedData)).data;
  },

  async updatePatientData(updatedData: PatientCuratorshipDataInputProps): Promise<User> {
    return (await api.post(`/patient-curatorship/`, updatedData)).data;
  },

  async fetchPatientData(patientSk: string | undefined, queue: string): Promise<any> {
    return (
      await api.get(`/patient-curatorship/patient-sk/${patientSk}/curatorship-queue/${queue}`)
    ).data;
  },

  async getUserByEmail(email: string): Promise<User> {
    return (await api.get(`/user-ad/find-one-user/${email}`)).data;
  },

  async createUser(createUser: {
    email: string;
    roles: string[];
    isActive: boolean;
  }): Promise<User> {
    return (await api.post(`/user/create`, createUser)).data;
  },

  async fetchPaginatedLogs(
    paginateOptions: { page: number; itemsPerPage: number },
    filters: any,
  ): Promise<any> {
    return (
      await api.post(
        `/log/find-all/page/${paginateOptions.page}/items-per-page/${paginateOptions.itemsPerPage}`,
        {
          filters,
        },
      )
    ).data;
  },

  async getLog(logName: string): Promise<any> {
    return (await api.get(`/log/get-blob-content/${logName}`)).data;
  },

  async curatePatientData(patientSk: string, data: PatientCuratorshipDataInputProps): Promise<any> {
    return (await api.post(`/patient-curatorship/patient-sk/${patientSk}`, data)).data;
  },

  async getSecondQueueIsEnable(): Promise<boolean> {
    return (await api.get(`/settings/get-second-queue-is-enable`)).data;
  },

  async changeSecondQueueIsEnable(): Promise<boolean> {
    return (await api.post(`/settings/change-second-queue-is-enable`)).data;
  },

  async fetchLogFilters(): Promise<LogFilter> {
    return (await api.get<LogFilter>(`/log/filters`)).data;
  },
};

export default apiRoutes;
