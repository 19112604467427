export type TableRowData = {
  id: string | number;
  col1: {
    name: string;
    hospital: string;
  };
  col3: string | number;
  col4: string | number;
};

export interface UserType {
  id: string;
  email: string;
  name: string;
  roles: string[];
  isActive: boolean;
}

export interface UserTypeEdit {
  id: string;
  email: string;
  roles: string[];
}

export const Mode = {
  ADD: 'add',
  EDIT: 'edit',
};

export const Type = {
  SYS_CURATOR: 'SYS_CURATOR',
  SYS_ADMIN: 'SYS_ADMIN',
};
