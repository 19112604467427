import { CSSProperties } from 'react';

import { grey, red } from 'components/Material';

import { GroupBase, StylesConfig } from 'react-select';
import { ReactSelectOption } from './types';

interface ReactSelectStyleProps {
  width: string | number;
  minHeight?: string | number;
  hasError: boolean;
  disabled?: boolean;
}

export function getBaseReactSelectStyle({
  width,
  hasError,
  minHeight,
  disabled,
}: ReactSelectStyleProps): StylesConfig<ReactSelectOption, boolean, GroupBase<ReactSelectOption>> {
  const style = {
    control: (styles: CSSProperties) => ({
      ...styles,
      background: disabled ? grey[400] : '#fff',
      borderRadius: '8px',
      borderColor: hasError ? red[500] : grey[400],
      fontSize: '1rem',
      width,
      minHeight: minHeight ?? 45,
      border: '1px solid #C9C9C9',
      boxShadow: 'none',
      opacity: disabled ? 0.5 : 1,
      '&:hover': {
        border: disabled ? '1px solid #C9C9C9' : '1px solid #00B5AD',
      },
    }),

    multiValue: (styles: CSSProperties) => ({
      ...styles,
      backgroundColor: '#00B5AD',
      borderRadius: '4px',
      color: 'white',
    }),

    multiValueRemove: (styles: CSSProperties) => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: 'gray',
        color: 'white',
      },
    }),

    multiValueLabel: (styles: CSSProperties) => ({
      ...styles,
      color: 'white',
    }),

    container: (styles: CSSProperties) => ({
      ...styles,
      width: width ?? '100%',
    }),

    singleValue: (styles: CSSProperties) => ({
      ...styles,
      color: 'rgba(0, 0, 0, 0.6)',
    }),

    indicatorSeparator: (styles: CSSProperties) => ({
      ...styles,
      display: 'none',
    }),

    dropdownIndicator: (styles: CSSProperties) => ({
      ...styles,
      color: grey[600],
      padding: '0',
      marginRight: '8px',
    }),

    menu: (styles: CSSProperties) => ({
      ...styles,
      fontSize: '1rem',
      margin: '0',
      borderRadius: '4px',
      color: '#474D59',
      padding: '8px',
    }),

    menuPortal: (styles: CSSProperties) => ({
      ...styles,
      zIndex: 9999,
    }),

    menuList: (styles: CSSProperties) => ({
      ...styles,

      scrollbarWidth: 'thin',
      scrollbarColor: '#888 transparent',
      overflowY: 'auto',

      '::-webkit-scrollbar': {
        width: '15px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f0f0f0',
        borderRadius: '12px',
        marginLeft: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#cbcbcb',
        borderRadius: '50px',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
      },
      '::-webkit-scrollbar-thumb:hover': {
        cursor: 'pointer',
      },
    }),

    option: (provided: CSSProperties, state: { isSelected: boolean }) => ({
      ...provided,
      padding: '8px',
      margin: '5px 0px',
      backgroundColor: state.isSelected ? '#F4F4F4' : 'white',
      color: state.isSelected ? '#474D59' : '#474D59',
      borderRadius: '6px',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: 'lightblue',
      },
    }),
  };

  return style as StylesConfig<ReactSelectOption, boolean, GroupBase<ReactSelectOption>>;
}
