import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppThemeProvider } from './Theme';
import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';
import { ConfirmationProvider } from './Confirmation';

import ErrorBoundary from '../components/ErrorBoundary';

import { GlobalStyle } from '../styles/global';

function AppProvider({ children }: { children: ReactNode }): JSX.Element {
  return (
    <BrowserRouter>
      <AppThemeProvider>
        <GlobalStyle />
        <ErrorBoundary>
          <ToastProvider>
            <ConfirmationProvider>
              <AuthProvider>
                <>{children}</>
              </AuthProvider>
            </ConfirmationProvider>
          </ToastProvider>
        </ErrorBoundary>
      </AppThemeProvider>
    </BrowserRouter>
  );
}

export default AppProvider;
