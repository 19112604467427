import { FormEvent, useState, FC, useRef } from 'react';

import { FaLock } from 'react-icons/fa';

import { InputWithIcon } from 'components/Inputs';
import FormWrapper from './FormWrapper';

import { useAuth } from '../../providers/Auth';

import { Typography } from 'components/Material';
import { useToast } from 'providers/Toast';

const SignInForm: FC = () => {
  const [password, setPassword] = useState('');
  const confirmNewPasswordRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const { completeNewPasswordAfterLogin } = useAuth();
  const { addToast } = useToast();

  const handleLogIn = async (formEvent: FormEvent): Promise<void> => {
    formEvent.preventDefault();

    const confirmNewPassword = confirmNewPasswordRef.current?.value;

    const invalidState = [!password, !confirmNewPassword, password !== confirmNewPassword].some(
      Boolean,
    );

    if (invalidState) return addToast({ title: 'As senhas devem ser iguais' });

    setLoading(true);

    const success = await completeNewPasswordAfterLogin(password);

    if (!success) setLoading(false);
  };

  return (
    <FormWrapper loading={loading} onSubmit={handleLogIn} buttonText="Trocar minha senha">
      <Typography color="GrayText" variant="h2">
        Bem vindo!
      </Typography>

      <Typography color="GrayText" variant="body2">
        Troque sua senha para algo seguro
      </Typography>

      <InputWithIcon
        icon={FaLock}
        name="newPassword"
        placeholder="Nova Senha"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
        type="password"
        required
      />

      <InputWithIcon
        icon={FaLock}
        name="confirmNewPassword"
        placeholder="Confirme a Senha"
        inputRef={confirmNewPasswordRef}
        type="password"
        required
      />
    </FormWrapper>
  );
};

export default SignInForm;
