import { AxiosRequestConfig } from 'axios';
import { keys } from 'config/localStorage';

import { AuthenticatedUser } from 'types/auth';

export async function addAuthTokenToRequest(
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> {
  const token = JSON.parse(localStorage.getItem(keys.user) || '')?.token;

  if (!token) return config;

  const authorization = `Bearer ${token}`;

  const updatedConfig = {
    ...config,
    headers: {
      ...config.headers,
      authorization,

      'X-Custom-Tz-Diff': `${new Date().getTimezoneOffset()}`,
    },
  };

  return updatedConfig;
}

function validateSession({ accessValidity }: AuthenticatedUser): boolean {
  console.log(accessValidity);
  try {
    // if (!accessValidity) return false;
    /*     if (!accessValidity) return false;



    const today = new Date();
    const accessLimit = subDays(new Date(accessValidity), 2);

    const accessLimitExpired = isBefore(accessLimit, today);

    return !accessLimitExpired; */

    return true;
  } catch (err) {
    return false;
  }
}

export function getSavedUserInfo(): AuthenticatedUser {
  const user = localStorage.getItem(keys.user);

  if (!user) return { data: {} } as AuthenticatedUser;

  const possible = JSON.parse(user);

  const validSession = validateSession(possible);

  if (validSession) return possible;

  [keys.user].forEach((key) => localStorage.removeItem(key));

  return { data: {} } as AuthenticatedUser;
}
