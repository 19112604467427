import { Column, Row } from 'components/Containers';
import { getBoxWidthAndHeight, getPadding } from 'css-blocks-styled-components';
import styled from 'styled-components';

export const Container = styled(Column)`
  ${getPadding(10, 20)}

  &.small {
    width: clamp(280px, 40vw, 500px);
  }

  &.medium {
    width: clamp(280px, 60vw, 750px);
  }

  &.big {
    width: clamp(280px, 90vw, 1200px);
  }

  max-height: 90vh;

  overflow-y: auto;
`;

export const IrreversibleWrapper = styled(Row)`
  svg {
    ${getBoxWidthAndHeight(32)}
  }
`;
