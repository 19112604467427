import { IconButtonProps } from '@mui/material/IconButton';

import { CloseIcon } from 'components/Icons';
import { StyledRemoveButton } from './styled';

type RemoveButtonProps = {
  children?: never;
  size?: number;
} & Omit<IconButtonProps, 'size' | 'sx'>;

function RemoveButton({ size = 24, ...props }: RemoveButtonProps): JSX.Element {
  return (
    <StyledRemoveButton $size={size} {...props}>
      <CloseIcon />
    </StyledRemoveButton>
  );
}

export default RemoveButton;
