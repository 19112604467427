/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from 'providers/Auth';
import { AuthorityConfig } from './config';
import AppWrapper from 'components/AppWrapper';
import { LoggedUser } from 'types/entities';
import { UserRolesEnum } from 'utils/enumerables';
interface BaseProps {
  isPrivate?: boolean;
  component?: ComponentType;
  redirect?: string;
  permanent?: boolean;
  userAuthorityRequired?: AuthorityConfig;
  pageKey?: string;
  requiredRoles?: UserRolesEnum[];

  customAuthorityValidator?: (userData: LoggedUser) => boolean;
}

type RouteOptions =
  | { component: ComponentType; redirect?: never }
  | { component?: never; redirect: string };

export type RouteProps = BaseProps & RouteOptions;

function Route({
  isPrivate = false,
  component: Component,
  redirect,
  pageKey,
  permanent,
}: RouteProps): JSX.Element | null {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [search] = useSearchParams();

  const isSigned = !!user.token;

  const RenderComponent = Component as any;

  if (redirect) return <Navigate to={redirect} replace />;

  if (!isPrivate && permanent) return <RenderComponent key={pageKey} />;

  const redirectAfter = isPrivate && pathname !== '/';

  const redirectPath = search.get('redirect');

  const loggedPath = redirectPath ? decodeURIComponent(redirectPath) : '/curadoria';

  return isPrivate === isSigned ? (
    RenderComponent && (
      <AppWrapper>
        <RenderComponent key={pageKey} />
      </AppWrapper>
    )
  ) : (
    <Navigate
      replace
      to={{
        pathname: isPrivate ? '/login' : loggedPath,
        search: redirectAfter ? `?redirect=${encodeURIComponent(pathname)}` : undefined,
      }}
    />
  );
}

export default Route;
