import { CheckBox, CheckBoxLabel, CheckBoxWrapper, Text } from './styles';

type SwitchComponentProps = {
  userId?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

function SwitchComponent({
  userId,
  checked,
  onChange,
  disabled,
}: SwitchComponentProps): JSX.Element {
  return (
    <CheckBoxWrapper>
      <CheckBox
        id={userId}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <CheckBoxLabel htmlFor={userId} />
      <Text isActive={checked}>{checked ? 'Ativo' : 'Inativo'}</Text>
    </CheckBoxWrapper>
  );
}

export default SwitchComponent;
