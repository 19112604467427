import { Autocomplete, Table, TableCell, TableHead } from '@mui/material';
import { convertPixelToRem } from 'css-blocks-styled-components';
import { Paper } from 'components/Material';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;

  h2 {
    color: #474d59;
    font-size: 20px;
    font-weight: 700;
    margin-left: 10px;
  }
`;

export const PaperContainer = styled(Paper)`
  margin-top: 10px;
  border-radius: 10px;
  flex-grow: 1;
  width: 100%;

  @media (max-width: 600px) {
    margin: 10px;
    padding: 10px;

    max-width: 79vw;
  }
`;

export const HeaderPaper = styled(Paper)`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;

    border-radius: 8px 8px 0 0;
  }
`;

export const ButtonAndSearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  align-items: center;

  .react-select {
    width: 100%;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiChip-root {
    background-color: #00b5ad;
    color: white;

    & .MuiChip-deleteIcon {
      color: white;
    }
  }
`;

export const StyledTable = styled(Table)`
  min-width: 650px;
`;

export const StyledTableCellHeader = styled(TableCell)`
  && {
    color: #535353;
    font-weight: 600;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    color: #989ea6;
    font-weight: 600;
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
    background: #f4f4f4;
    color: #989ea6;
  }
`;

export const StyledUserName = styled.div`
  color: #636973;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export const Count = styled.span`
  font-weight: bold;
  font-size: ${convertPixelToRem(14)};
  color: #00b5ad;
  margin-right: ${convertPixelToRem(5)};
  padding: 5px 8px;
  border-radius: ${convertPixelToRem(20)};
  background-color: #e7faf9;
`;

export const StyledDivCount = styled.div`
  display: flex;
  gap: 5px;
`;
