/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  ButtonsContainer,
  CenteredContainer,
  Container,
  PatientSummary,
  StyledDivButtons,
  StyledTabsContainer,
} from './styles';
import { Box, CircularProgress } from 'components/Material';
import Tabs from 'components/Tabs';
import { useEffect, useState } from 'react';
import { tabs } from './helpers';
import PatientDataTab from './components/PatientDataTab';
import TreatmentDataTab from './components/TreatmentDataTab';
import GenomicsDataTab from './components/GenomicsDataTab';
import ComorbiditiesDataTab from './components/ComorbiditiesDataTab';
import LaboratoryDataTab from './components/LaboratoryDataTab';
import PathologyDataTab from './components/PathologyDataTab';
import OutcomeDataTab from './components/OutcomeDataTab';
import apiRoutes from 'services/apiRoutes';
import { StyledButton, StyledButtonNoBackGroundGray } from 'components/Buttons/styled';
import { useToast } from 'providers/Toast';
import PatientInfo from './components/PatientInfo';
import { InputValues, Origins } from 'types/entities';
import SystemicTreatmentDataTab from './components/SystemicTreatmentDataTab';
import TmoDataTab from './components/TmoDataTab';

const INITIAL_PATIENT = {
  anamnesis: [],
  clinicalNotes: [],
  origin: [],
  prescription: [],
};

const NOT_FOUND_INDEX = -1;

function Patient(): JSX.Element {
  const navigate = useNavigate();

  const { patientSk } = useParams();

  const { addToast } = useToast();

  const location = useLocation();

  const patient = location.state;

  const queueType = location.state?.queueType;

  const [isPageLoading, setPageLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState('tab1');

  const [patientData, setPatientData] = useState<Origins>(INITIAL_PATIENT);

  const [inputValues, setInputValues] = useState<InputValues[]>([]);

  const [originalInputValues, setOriginalInputValues] = useState<InputValues[]>([]);

  const [origin, setOrigin] = useState('origin');

  const [updateTrigger, setUpdateTrigger] = useState(0);

  const handleChangeTab = (value: string): void => {
    setSelectedTab(value);
  };

  const handleToPatients = (): void => {
    navigate('/curadoria');
  };

  const handleChangeInput = (
    attribute: string,
    group: string,
    value: string,
    attributeDescription: string,
    sourceOfInformation: string,
    service: string,
    date: Date | null,
  ): void => {
    const input = {
      group,
      attribute,
      attributeDescription,
      sourceOfInformation,
      service,
      date,
      value,
    };

    const inputValuesCopy = [...inputValues];
    const inputIndex = inputValuesCopy.findIndex((input) => input.attribute === attribute);

    if (inputIndex === NOT_FOUND_INDEX) {
      inputValuesCopy.push(input);
    } else {
      inputValuesCopy[inputIndex] = input;
    }

    setInputValues(inputValuesCopy);
  };

  const fetchPatient = async (): Promise<void> => {
    try {
      setPageLoading(true);

      const response = await apiRoutes.fetchdPatientData(patientSk);

      setPatientData(response);

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Erro ao buscar dados do paciente.',
      });
    } finally {
      setPageLoading(false);
    }
  };

  const fetchData = async (): Promise<void> => {
    setPageLoading(true);

    try {
      const response = await apiRoutes.fetchPatientData(patientSk, queueType);

      const mappedResponse = response?.map((item: InputValues) => ({
        group: item.group,
        attribute: item.attribute,
        value: item.value,
        sourceOfInformation: item.sourceOfInformation,
        service: item.service,
        date: item.date,
      }));

      setInputValues(mappedResponse);

      setOriginalInputValues(mappedResponse);
    } catch (error) {
      error;
    } finally {
      setPageLoading(false);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setPageLoading(true);

      const updatedData = inputValues.filter((inputValue) => {
        const originalValue = originalInputValues.find(
          (originalInput) => originalInput.attribute === inputValue.attribute,
        );

        return !originalValue || originalValue.value !== inputValue.value;
      });

      if (updatedData.length > 0) {
        const { percentage, queueType, ...patientWithoutPercentageAndQueueType } = patient;

        const payload = {
          queue: queueType,
          patient: patientWithoutPercentageAndQueueType,
          data: updatedData,
        };

        await apiRoutes.updatePatientData(payload);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Dados do paciente atualizados com sucesso.',
        });

        setInputValues([]);

        fetchData();

        setUpdateTrigger((prev) => prev + 1);
      } else {
        addToast({
          type: 'info',
          title: 'Nenhuma Mudança',
          description: 'Não há alterações para atualizar.',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao atualizar os dados do paciente.',
      });
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPatient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!patientData ? (
        <CenteredContainer>
          <CircularProgress />
        </CenteredContainer>
      ) : (
        <Container>
          <Box style={{ flex: 1 }}>
            <PatientSummary>
              <span>Resumo do Paciente</span>

              <PatientInfo patientSk={patientSk} selectedPatient={patient} />
            </PatientSummary>
            <>
              <StyledTabsContainer>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  tabs={tabs}
                  style="normal"
                  fullWidth
                  addHorizontalLineUnderneath
                />
              </StyledTabsContainer>

              {selectedTab === 'tab1' && (
                <PatientDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="paciente"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab2' && (
                <ComorbiditiesDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="historico_saude"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab3' && (
                <LaboratoryDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="lab"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab4' && (
                <PathologyDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="patologia"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab5' && (
                <GenomicsDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="genomica"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab6' && (
                <TreatmentDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="tratamento"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab7' && (
                <SystemicTreatmentDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="tratamento_sistemico"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab8' && (
                <TmoDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="tmo"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}

              {selectedTab === 'tab9' && (
                <OutcomeDataTab
                  key={updateTrigger}
                  patientSk={patientSk}
                  group="desfecho"
                  handleChangeInput={handleChangeInput}
                  inputValues={inputValues}
                  origin={origin}
                  setOrigin={setOrigin}
                  queueType={queueType}
                />
              )}
            </>

            <ButtonsContainer>
              <StyledDivButtons>
                <StyledButtonNoBackGroundGray
                  variant="contained"
                  sx={{ width: '100%' }}
                  onClick={handleToPatients}
                >
                  Cancelar
                </StyledButtonNoBackGroundGray>

                <StyledButton
                  variant="contained"
                  sx={{ width: '100%' }}
                  onClick={handleSubmit}
                  disabled={isPageLoading}
                >
                  {isPageLoading ? <CircularProgress size={24} /> : 'Salvar'}
                </StyledButton>
              </StyledDivButtons>
            </ButtonsContainer>
          </Box>
        </Container>
      )}
    </>
  );
}

export default Patient;
