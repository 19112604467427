/* eslint-disable prettier/prettier */
import { UserIcon } from 'components/Icons';
import Modal from 'components/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  ButtonsContainer,
  InputContainer,
  StyledCard,
  StyledDiv,
  StyledDivCardBody,
  StyledTypography,
  StyledTypographyPermission,
  SubTitle,
  TitleModal,
  UserName,
} from '../styles';
import {
  Box,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  InputLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { StyledButton, StyledButtonNoBackGroundGray } from 'components/Buttons/styled';
import UserCicle from 'components/Icons/UserCircle';
import apiRoutes from 'services/apiRoutes';
import { useToast } from 'providers/Toast';
import { Mode, Type, UserType, UserTypeEdit } from '../types';
import Edit from 'components/Icons/Edit';

type AlignmentType = string[];

interface UserModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  findUserByEmail: (email: string) => void;
  user: UserType | null;
  selectedUsers: string[];
  handleSelectCardUser: (id: string) => void;
  role: AlignmentType;
  handleSelectRole: (event: React.MouseEvent<HTMLElement>, newRoles: string[]) => void;
  openModal: () => void;
  setUserCreated: React.Dispatch<React.SetStateAction<boolean>>;
  mode: string;
}

interface CreateUserParams {
  email: string;
  roles: string[];
  isActive: boolean;
}

const selectedStyle = { background: '#00B5AD', color: 'white', width: '100%' };
const notSelectedStyle = { background: 'white', color: 'black', width: '100%' };
const disabledStyle = { color: 'gray', width: '100%', opacity: '0.5' };
const disabledAdminStyle = { color: 'gray', width: '100%', opacity: '0.5' };

const UserModal: React.FC<UserModalProps> = ({
  isModalOpen,
  closeModal,
  email,
  setEmail,
  isLoading,
  findUserByEmail,
  user,
  selectedUsers,
  handleSelectCardUser,
  role,
  handleSelectRole,
  setUserCreated,
  mode,
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const { addToast } = useToast();

  const createUser = async (user: CreateUserParams): Promise<void> => {
    try {
      setIsCreating(true);

      const response = await apiRoutes.createUser(user);
      if (response) {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Usuário adicionado com sucesso',
        });

        closeModal();
        setUserCreated(true);
        setEmail('');
      } else {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Ocorreu um erro ao adicionar o usuário.',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Usuário já cadastrado no sistema.',
      });
    } finally {
      setIsCreating(false);
    }
  };

  const editUser = async (user: UserTypeEdit): Promise<void> => {
    try {
      setIsCreating(true);
      const response = await apiRoutes.updateUser(user.id, user);

      if (response) {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Usuário editado com sucesso',
        });

        closeModal();
        setUserCreated(true);
        setEmail('');
      } else {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Ocorreu um erro ao editar o usuário.',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Error',
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleAddClick = (): void => {
    if (!user) return;

    const userDetails = {
      email: email,
      roles: role.map((role: string) => role.toUpperCase()),
      isActive: user.isActive,
    };

    if (mode === Mode.ADD) {
      createUser(userDetails);
    } else if (mode === Mode.EDIT && user) {
      editUser({ ...userDetails, id: user.id });
    }
  };

  const isAddMode = mode === Mode.ADD;

  const buttonIcon = isCreating ? null : isAddMode ? (
    <UserIcon color="white" />
  ) : (
    <Edit color="white" size={15} />
  );

  const buttonText = isCreating ? (
    <CircularProgress size={24} color="inherit" />
  ) : isAddMode ? (
    'Adicionar'
  ) : (
    'Editar'
  );

  return (
    <Modal
      title={
        mode === Mode.ADD ? (
          <UserIcon color="#636973" size={30} />
        ) : (
          <Edit color="#636973" size={25} />
        )
      }
      isOpen={isModalOpen}
      close={closeModal}
    >
      <TitleModal>{mode === Mode.EDIT ? 'Editar Usuário' : 'Adicionar Usuário'}</TitleModal>

      {mode === Mode.ADD && (
        <>
          <SubTitle>Preencha as informações abaixo.</SubTitle>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              findUserByEmail(email);
            }}
          >
            <InputContainer>
              <InputLabel
                htmlFor="outlined-adornment-email"
                sx={{ marginBottom: '4px', color: '#474D59', fontSize: '14px' }}
              >
                E-mail
              </InputLabel>

              <TextField
                id="outlined-adornment-email"
                size="small"
                required
                fullWidth
                placeholder="usuario@oncoclinicas.com"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                sx={{ borderRadius: 10 }}

              />
            </InputContainer>

            <ButtonsContainer>
              <StyledButtonNoBackGroundGray
                sx={{ width: '100%' }}
                variant="contained"
                onClick={closeModal}
              >
                Cancelar
              </StyledButtonNoBackGroundGray>

              <StyledButton
                variant="contained"
                type="submit"
                sx={{ width: '100%' }}
                endIcon={isLoading ? <></> : <UserIcon color="white" />}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : <>Buscar</>}
              </StyledButton>
            </ButtonsContainer>
          </form>
        </>
      )}

      {user && (
        <>
          {Object.keys(user).length > 0 && (
            <>
              {mode === Mode.ADD && <Divider sx={{ marginTop: '20px' }} />}

              <StyledDiv>
                {mode === Mode.ADD && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    Usuário encontrado, selecione para adicionar.
                  </Typography>
                )}

                <StyledCard
                  key={user.email}
                  isSelected={selectedUsers.includes(user.email)}
                  onClick={() => handleSelectCardUser(user.email)}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                      <StyledDivCardBody>
                        <UserCicle color={selectedUsers.includes(user.email) ? 'white' : 'gray'} />

                        <Box marginLeft={2}>
                          <UserName variant="h6">{user.name}</UserName>

                          <StyledTypography
                            isSelected={selectedUsers.includes(user.email)}
                            variant="body2"
                            color="text.secondary"
                          >
                            {user.email}
                          </StyledTypography>
                        </Box>
                      </StyledDivCardBody>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedUsers.includes(user.email)}
                            style={{
                              color: selectedUsers.includes(user.email) ? 'white' : undefined,
                            }}
                          />
                        }
                        label=""
                      />
                    </Box>
                  </CardContent>
                </StyledCard>

                <StyledTypographyPermission variant="body2" color="text.secondary">
                  {mode === Mode.EDIT ? 'Edite' : 'Selecione'} a permissão{' '}
                  {mode === Mode.EDIT ? 'do' : 'para'} o usuário.
                </StyledTypographyPermission>

                <ToggleButtonGroup
                  value={role}
                  onChange={handleSelectRole}
                  aria-label="role"
                  fullWidth
                >
                  <ToggleButton
                    value={Type.SYS_CURATOR}
                    disabled={!selectedUsers.length}
                    aria-label={Type.SYS_CURATOR}
                    style={
                      !selectedUsers.length
                        ? disabledStyle
                        : role.includes(Type.SYS_CURATOR)
                          ? selectedStyle
                          : notSelectedStyle
                    }
                  >
                    Curador
                  </ToggleButton>
                  <ToggleButton
                    value={Type.SYS_ADMIN}
                    disabled={!selectedUsers.length}
                    aria-label={Type.SYS_ADMIN}
                    style={
                      !selectedUsers.length
                        ? disabledAdminStyle
                        : role.includes(Type.SYS_ADMIN)
                          ? selectedStyle
                          : notSelectedStyle
                    }
                  >
                    Administrador
                  </ToggleButton>
                </ToggleButtonGroup>

                <ButtonsContainer>
                  <StyledButton
                    variant="contained"
                    disabled={!selectedUsers.length || isCreating}
                    sx={{ width: '100%', marginTop: '20px' }}
                    endIcon={buttonIcon}
                    onClick={handleAddClick}
                  >
                    {buttonText}
                  </StyledButton>
                </ButtonsContainer>
              </StyledDiv>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default UserModal;
