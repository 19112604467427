import { Box, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  ButtonAndSearchContainer,
  Container,
  Count,
  HeaderPaper,
  PaperContainer,
  StyledBox,
  StyledBoxFieldsCurator,
  StyledBoxOpenPatient,
  StyledBoxPercentage,
  StyledCircularProgress,
  StyledCircularProgressPrimary,
  StyledCircularProgressWhite,
  StyledOpenInNewIcon,
  StyledOpenInNewIconDisabled,
  StyledSlider,
  StyledSpan,
  StyledSpanDisabled,
  StyledTable,
  StyledTableCell,
  StyledTableCellColorTextGreen,
  StyledTableCellHeader,
  StyledTableHead,
  StyledTabsContainer,
  StyledTypography,
  UserAndCountContainer,
} from './styles';

import { useEffect, useState } from 'react';
import apiRoutes from 'services/apiRoutes';
import { Patient } from 'types/entities';
import Select from 'components/Select';

import { useNavigate } from 'react-router-dom';

import SkeletonTable from './components/Skeleton';
import { Button } from 'components/Buttons';
import TextInput from 'components/Inputs/TextInput';
import CustomPagination from 'components/Pagination/Pagination';
import { useAuth } from 'providers/Auth';
import { UserRolesEnum } from 'utils/enumerables';
import Tabs from 'components/Tabs';
import { tabs } from './helpers';
import { FIRST_CURATORSHIP, SECOND_CURATORSHIP } from 'pages/Patient/helpers';

const ITEMS_PER_PAGE = 10;

function Patients(): JSX.Element {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);

  const [paginatePatients, setPaginatedPatients] = useState<any>();

  const [value, setValue] = useState<number[]>([0, 100]);

  const [dead, setDead] = useState<string>('');

  const [selectedTab, setSelectedTab] = useState('tab1');

  const userRoles = user.data.roles;

  const hasAnotherPermission = userRoles.includes(UserRolesEnum.SYS_CURATOR);

  const isDisabled = userRoles.includes(UserRolesEnum.SYS_ADMIN) && !hasAnotherPermission;

  const [isSecondQueueEnabled, setIsSecondQueueEnabled] = useState<boolean>(false);

  const [filter, setFilter] = useState<Patient>({
    patientSk: '',
    unityName: '',
    cidCode: '',
    isDead: dead === 'true' ? true : dead === 'false' ? false : null,
    percentage: 0,
    percentageRange: [0, 100],
  });

  const [isPageLoading, setPageLoading] = useState(false);

  const handlePatientClick = (patient: Patient): void => {
    const queueType = selectedTab === 'tab1' ? FIRST_CURATORSHIP : SECOND_CURATORSHIP;

    navigate(`/curadoria/${patient.patientSk}`, {
      state: {
        ...patient,
        queueType: queueType,
      },
    });
  };

  const fetchPatients = async (): Promise<void> => {
    setPageLoading(true);

    let response;

    if (selectedTab === 'tab1') {
      response = await apiRoutes.fetchPaginatedPatients(
        { page, itemsPerPage: ITEMS_PER_PAGE },
        filter,
      );
    } else if (selectedTab === 'tab2') {
      response = await apiRoutes.fetchPaginatedPatientsSecondQueue(
        { page, itemsPerPage: ITEMS_PER_PAGE },
        filter,
      );
    }

    setPaginatedPatients(response);
    setPageLoading(false);
  };

  const handleChangeTab = (newTab: string): void => {
    if (newTab !== selectedTab) {
      setSelectedTab(newTab);
      setPage(1);
    }
  };

  useEffect(() => {
    fetchPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, page]);

  useEffect(() => {
    const fetchSecondQueueState = async (): Promise<void> => {
      try {
        const isEnabled = await apiRoutes.getSecondQueueIsEnable();

        setIsSecondQueueEnabled(isEnabled);
      } catch (error) {
        error;
      }
    };

    fetchSecondQueueState();
  }, []);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeathOptionChange = (value: string): void => {
    const isDeadValue: boolean | null = value === 'true' ? true : value === 'false' ? false : null;
    setDead(value);
    setFilter((prevState) => ({
      ...prevState,
      isDead: isDeadValue,
    }));
  };

  const deathOptions = [
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' },
  ];

  const handleChange = (_: Event, newValue: number | number[]): void => {
    const newPercentageRange = Array.isArray(newValue) ? newValue : [newValue, newValue];

    setValue(newPercentageRange);

    setFilter((prevState) => ({
      ...prevState,
      percentageRange: newPercentageRange,
    }));
  };

  function valuetext(value: number): string {
    return `${value}`;
  }

  return (
    <Container>
      <StyledTabsContainer>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          tabs={isSecondQueueEnabled ? tabs : tabs.filter((tab) => tab.id !== 'tab2')}
          style="normal"
          fullWidth
        />
      </StyledTabsContainer>

      {(selectedTab === 'tab1' || selectedTab === 'tab2') && (
        <PaperContainer>
          <HeaderPaper>
            <UserAndCountContainer>
              <StyledSpan>
                Total
                <StyledBox>
                  {isPageLoading ? (
                    <StyledCircularProgress size={16} />
                  ) : (
                    <Count>{paginatePatients?.total.toLocaleString()}</Count>
                  )}
                </StyledBox>
              </StyledSpan>
            </UserAndCountContainer>

            <ButtonAndSearchContainer>
              <TextInput
                placeholder="SK"
                id="outlined-start-adornment"
                size="small"
                name="patientSk"
                onChange={handleChangeFilter}
              />

              <TextInput
                placeholder="Unidade"
                id="outlined-start-adornment"
                size="small"
                name="unityName"
                onChange={handleChangeFilter}
              />

              <TextInput
                placeholder="CID"
                id="outlined-start-adornment"
                sx={{ width: '10%' }}
                size="small"
                name="cidCode"
                onChange={handleChangeFilter}
              />

              <Select
                placeholder="Óbito"
                minHeight={40}
                width={165}
                id="outlined-start-adornment"
                options={deathOptions}
                propConfig={{ unique: 'value', label: 'label' }}
                value={dead}
                onChange={(value) => handleDeathOptionChange(value)}
              />

              <StyledBoxFieldsCurator>
                <span>Total Curado</span>

                <StyledSlider
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  disableSwap
                  sx={{
                    '& .MuiSlider-thumb': {
                      color: '#ffff',
                    },
                    '&:hover .MuiSlider-valueLabel': {
                      backgroundColor: 'white',
                      border: '1px solid #00B5AD',
                    },
                  }}
                />
              </StyledBoxFieldsCurator>

              <Button variant="contained" onClick={() => fetchPatients()}>
                {isPageLoading ? <StyledCircularProgress size={16} /> : 'Buscar'}
              </Button>
            </ButtonAndSearchContainer>
          </HeaderPaper>

          {isPageLoading ? (
            <SkeletonTable />
          ) : (
            <>
              <TableContainer>
                <StyledTable>
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCellHeader>SK Paciente</StyledTableCellHeader>

                      <StyledTableCellHeader>Unidade</StyledTableCellHeader>

                      <StyledTableCellHeader>CID</StyledTableCellHeader>

                      <StyledTableCellHeader>Óbito</StyledTableCellHeader>

                      <StyledTableCellHeader>Total Curado</StyledTableCellHeader>

                      <StyledTableCellHeader>Curar</StyledTableCellHeader>
                    </TableRow>
                  </StyledTableHead>

                  <TableBody>
                    {paginatePatients?.data?.map((item: Patient) => (
                      <TableRow key={item.patientSk}>
                        <StyledTableCellColorTextGreen isDisabled={isDisabled}>
                          {isDisabled ? (
                            <StyledSpanDisabled>{item.patientSk}</StyledSpanDisabled>
                          ) : (
                            <span
                              onClick={() => handlePatientClick(item)}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                cursor: 'pointer',
                              }}
                            >
                              {item.patientSk}
                            </span>
                          )}
                        </StyledTableCellColorTextGreen>

                        <StyledTableCell>{item.unityName}</StyledTableCell>

                        <StyledTableCell>
                          {item.cidCode ? item.cidCode : 'Não Informado'}
                        </StyledTableCell>

                        <StyledTableCell>{item.isDead ? 'Sim' : 'Não'}</StyledTableCell>

                        <TableCell>
                          <StyledBoxPercentage>
                            <StyledCircularProgressWhite
                              variant="determinate"
                              value={100}
                              size={55}
                              thickness={5}
                            />

                            <StyledCircularProgressPrimary
                              variant="determinate"
                              value={item.percentage}
                              size={55}
                              thickness={5}
                            />
                            <Box
                              top={0}
                              left={0}
                              bottom={0}
                              right={0}
                              position="absolute"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <StyledTypography variant="caption" color="text.secondary">
                                {item.percentage ? item.percentage : 0}%
                              </StyledTypography>
                            </Box>
                          </StyledBoxPercentage>
                        </TableCell>

                        <TableCell>
                          <StyledBoxOpenPatient>
                            {isDisabled ? (
                              <StyledSpanDisabled>
                                <StyledOpenInNewIconDisabled />
                              </StyledSpanDisabled>
                            ) : (
                              <span
                                onClick={() => handlePatientClick(item)}
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                  cursor: 'pointer',
                                }}
                              >
                                <StyledOpenInNewIcon />
                              </span>
                            )}
                          </StyledBoxOpenPatient>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>

              <CustomPagination
                paginatePatients={paginatePatients}
                page={page}
                handlePageChange={handlePageChange}
              />
            </>
          )}
        </PaperContainer>
      )}
    </Container>
  );
}

export default Patients;
