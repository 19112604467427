import React, { forwardRef } from 'react';
import './ScrollerStyles.css';

interface ScrollerProps {
  style: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const Scroller = forwardRef<HTMLDivElement, ScrollerProps>(({ style, ...props }, ref) => {
  return <div className="custom-scrollbar" style={{ ...style }} ref={ref} {...props} />;
});

Scroller.displayName = 'Scroller';

export default Scroller;
