import styled, { css } from 'styled-components';
import { getScrollbarStyle } from 'css-blocks-styled-components';

import { Dialog, grey } from 'components/Material';
import { Column } from 'components/Containers';

import { QUERIES } from 'utils/constants';

interface ModalWrapperProps {
  $width: string;
  $maxWidth?: string;
}

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
    max-width: unset;
  }

  ${QUERIES.LAPTOPS} {
    /* to fix horizontal scroll appearing when on mobile, due to 90vw sizing */
    .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
      margin: calc(4vw);
    }
  }
`;

export const ModalWrapper = styled(Column)<ModalWrapperProps>`
  padding: 20px;

  overflow-y: auto;

  ${getScrollbarStyle({
    scrollColor: grey[500],
  })}

  min-width: 25vw;

  width: ${({ $width }) => $width};

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}

  ${QUERIES.PHONES} {
    width: 90vw;
    max-width: 90vw;
  }
`;
