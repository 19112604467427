import Add from 'components/Icons/Add';
import MedicalCross from 'components/Icons/MedicalCross';
import File from 'components/Icons/File';
import { UserRolesEnum } from 'utils/enumerables';
import { Settings } from 'components/Icons';

export const options = [
  {
    name: 'Usuários',
    path: '/',
    icon: Add,
    roles: [UserRolesEnum.SYS_ADMIN],
  },
  {
    name: 'Curadoria',
    path: '/curadoria',
    icon: MedicalCross,
    roles: [UserRolesEnum.SYS_ADMIN, UserRolesEnum.SYS_CURATOR],
  },
  {
    name: 'Logs',
    path: '/logs',
    icon: File,
    roles: [UserRolesEnum.SYS_ADMIN],
  },
  {
    name: 'Configurações',
    path: '/settings',
    icon: Settings,
    roles: [UserRolesEnum.SYS_ADMIN],
  },
];
