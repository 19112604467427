import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PaginationContainer } from './styles';

interface CustomPaginationProps {
  paginatePatients: {
    numberOfPages: number;
  };
  page: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  paginatePatients = { numberOfPages: 0 },
  page,
  handlePageChange,
}) => {
  return (
    <PaginationContainer>
      <Pagination
        count={paginatePatients.numberOfPages}
        shape="rounded"
        page={page}
        onChange={handlePageChange}
        renderItem={(item) => {
          if (item.type === 'previous') {
            return (
              <PaginationItem
                {...item}
                sx={{ border: '1px solid #CED3D9' }}
                component={ArrowBackIcon}
              />
            );
          }
          if (item.type === 'next') {
            return (
              <PaginationItem
                {...item}
                sx={{ border: '1px solid #CED3D9' }}
                component={ArrowForwardIcon}
              />
            );
          }

          return <PaginationItem sx={{ color: '#7B868C' }} {...item} />;
        }}
      />
    </PaginationContainer>
  );
};

export default CustomPagination;
