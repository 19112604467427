import { ReactNode, CSSProperties, useMemo, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { convertCSSinJS } from 'styles/helper';
import { DefinedBreakpointQueries, QUERIES } from 'utils/constants';
import { removeProperties } from 'utils/object';

type FlexCss = Pick<CSSProperties, 'gap' | 'alignItems' | 'justifyContent' | 'flexWrap'>;

export interface RowProps extends FlexCss {
  children: ReactNode;
  className?: string;
  as?: 'header' | 'div' | 'article' | 'footer';
  fullWidth?: boolean;
  title?: string;

  containerRef?: RefObject<HTMLDivElement> | ((node: HTMLDivElement | null) => void);

  invertOrientation?: {
    breakpoint: DefinedBreakpointQueries;
  } & FlexCss;
}

const RowBase = styled.div<{
  $fullWidth: boolean;
  $style: FlexCss;
  $invert?: RowProps['invertOrientation'];
}>`
  display: flex;

  ${({ $style }) => convertCSSinJS($style)}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  ${({ $invert }) =>
    $invert &&
    css`
      ${QUERIES[$invert.breakpoint]} {
        flex-direction: column;
        ${convertCSSinJS(removeProperties($invert, ['breakpoint']))}
      }
    `};
`;

function Row({
  children,
  className,
  gap = 16,
  alignItems = 'center',
  as,
  title,
  fullWidth = true,
  containerRef,
  invertOrientation,
  ...styles
}: RowProps): JSX.Element {
  const actualStyles = useMemo(() => ({ ...styles, gap, alignItems }), [gap, alignItems, styles]);

  const invertStyles = useMemo(
    () => ({ gap, alignItems, ...invertOrientation }),
    [gap, alignItems, invertOrientation],
  );

  return (
    <RowBase
      title={title}
      ref={containerRef}
      as={as}
      className={className}
      $fullWidth={fullWidth}
      $style={actualStyles}
      $invert={invertOrientation ? (invertStyles as RowProps['invertOrientation']) : undefined}
    >
      {children}
    </RowBase>
  );
}

export default Row;
