/* eslint-disable prettier/prettier */
import { useCallback, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  DATE_NAME,
  ONE_TYPE_CHECKBOX,
  OriginOptions,
  SECOND_CURATORSHIP,
  TWO_TYPE_CHECKBOX,
} from '../../helpers';
import { TextField } from '@mui/material';
import Select from 'components/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { DomainItem } from '../../types';
import { format } from 'date-fns';
import { StyledButtonNoBackGround } from 'components/Buttons/styled';
import { StyledContainer, StyledDivInput, StyledUpdateIcon } from 'pages/Patient/styles';
import TrackingModal from '../Tracking/TrackingModal';
import { InputValues } from 'types/entities';

const CustomInput: React.FC<{
  attributeName: string;
  attributeType: string;
  attributeDescription: string;
  sourceOfInformation: string;
  service: string;
  date: Date | null;
  group: string;
  inputValues: InputValues[];
  origin: string;
  queueType: string;
  handleChangeInput: (
    attribute: string,
    group: string,
    value: string | string[] | null | Date,
    attributeDescription: string,
    sourceOfInformation: string,
    service: string,
    date: Date | null,
  ) => void;
  item: DomainItem;
  category: string;
}> = ({
  attributeName,
  attributeType,
  attributeDescription,
  group,
  inputValues,
  handleChangeInput,
  item,
  category,
  origin,
  queueType,
  sourceOfInformation,
  service,
  date,
}) => {
    const [modalSaved, setModalSaved] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [trackingInfo, setTrackingInfo] = useState({
      sourceOfInformations: '',
      serviceTracking: '',
      dateTracking: new Date() as Date | null,
    });

    const openModal = (): void => {
      if (!modalSaved) {
        let specificSourceOfInformation, specificService, specificDate;

        if (queueType === SECOND_CURATORSHIP) {
          specificSourceOfInformation = sourceOfInformation;
          specificService = service;
          specificDate = date;
        } else {
          specificSourceOfInformation =
            inputValues.find((input) => input.group === category && input.attribute === attributeName)
              ?.sourceOfInformation || '';

          specificService =
            inputValues.find((input) => input.group === category && input.attribute === attributeName)
              ?.service || '';

          specificDate =
            inputValues.find((input) => input.group === category && input.attribute === attributeName)
              ?.date || null;
        }

        setTrackingInfo({
          sourceOfInformations: specificSourceOfInformation || OriginOptions[origin],
          serviceTracking: specificService || '',
          dateTracking: specificDate || null,
        });
      }

      setIsModalOpen(true);
    };

    const closeModal = (): void => {
      setIsModalOpen(false);
      setModalSaved(false);
    };

    let attributeValue = inputValues.find(
      (input) => input.group === category && input.attribute === attributeName,
    )?.value;

    const handleUpdateSourceOfInformation = useCallback(
      (newSource: string) => {
        setTrackingInfo((prevState) => ({
          ...prevState,
          sourceOfInformations: newSource,
        }));

        handleChangeInput(
          attributeName,
          group,
          attributeValue ?? '',
          attributeDescription,
          newSource,
          trackingInfo.serviceTracking,
          trackingInfo.dateTracking,
        );
      },
      [attributeName, group, attributeValue, attributeDescription, trackingInfo, handleChangeInput],
    );

    const handleUpdateService = useCallback(
      (newService: string) => {
        setTrackingInfo((prevState) => ({
          ...prevState,
          serviceTracking: newService,
        }));

        handleChangeInput(
          attributeName,
          group,
          attributeValue ?? '',
          attributeDescription,
          trackingInfo.sourceOfInformations,
          newService,
          trackingInfo.dateTracking,
        );
      },
      [attributeName, group, attributeValue, attributeDescription, trackingInfo, handleChangeInput],
    );

    const handleUpdateDate = (newDate: Date | null): void => {
      setTrackingInfo((prevState) => ({
        ...prevState,
        dateTracking: newDate,
      }));

      handleChangeInput(
        attributeName,
        group,
        attributeValue ?? '',
        attributeDescription,
        trackingInfo.sourceOfInformations,
        trackingInfo.serviceTracking,
        newDate,
      );
    };

    const isMulti = attributeType === ONE_TYPE_CHECKBOX || attributeType === TWO_TYPE_CHECKBOX;

    if (item.domains[0] === DATE_NAME) {

      return (
        <>
          <TrackingModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            openModal={openModal}
            sourceOfInformation={trackingInfo.sourceOfInformations}
            service={trackingInfo.serviceTracking}
            date={trackingInfo.dateTracking}
            onUpdateSourceOfInformation={handleUpdateSourceOfInformation}
            onUpdateService={handleUpdateService}
            onUpdateDate={handleUpdateDate}
            origin={origin}
            setModalSaved={setModalSaved}
            sourceInformationDisabled={sourceOfInformation}
            serviceDisabled={service}
            dateDisabled={date}
          />

          <StyledContainer>
            <StyledDivInput>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  disabled={!attributeDescription}
                  value={attributeValue || null}
                  onChange={(value) =>
                    handleChangeInput(
                      attributeName,
                      group,
                      value,
                      attributeDescription,
                      trackingInfo.sourceOfInformations,
                      trackingInfo.serviceTracking,
                      trackingInfo.dateTracking,
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          ...params.InputProps?.style,
                          height: '45px',
                          borderRadius: '8px',
                        },
                      }}
                      style={{
                        width: '300px',
                        margin: '0',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </StyledDivInput>

            <StyledButtonNoBackGround
              variant="contained"
              endIcon={<StyledUpdateIcon />}
              onClick={openModal}
            >
              Rastreio
            </StyledButtonNoBackGround>
          </StyledContainer>
        </>
      );
    } else if (isMulti) {
      return (
        <>
          <TrackingModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            openModal={openModal}
            sourceOfInformation={trackingInfo.sourceOfInformations}
            service={trackingInfo.serviceTracking}
            date={trackingInfo.dateTracking}
            onUpdateSourceOfInformation={handleUpdateSourceOfInformation}
            onUpdateService={handleUpdateService}
            onUpdateDate={handleUpdateDate}
            origin={origin}
            setModalSaved={setModalSaved}
            sourceInformationDisabled={sourceOfInformation}
            serviceDisabled={service}
            dateDisabled={date}
          />

          <StyledContainer>
            <StyledDivInput>
              <Select
                isMulti
                placeholder="Auto Completar"
                id={`outlined-start-adornment-${attributeName}`}
                options={(item?.domains || []).map((domain: string) => ({
                  value: domain,
                  label: domain,
                }))}
                disabled={!attributeDescription}
                value={
                  Array.isArray(attributeValue)
                    ? attributeValue
                    : attributeValue
                      ? [attributeValue]
                      : []
                }
                propConfig={{ unique: 'value', label: 'label' }}
                onChange={(value) =>
                  handleChangeInput(
                    attributeName,
                    group,
                    value,
                    attributeDescription,
                    trackingInfo.sourceOfInformations,
                    trackingInfo.serviceTracking,
                    trackingInfo.dateTracking,
                  )
                }
              />
            </StyledDivInput>

            <StyledButtonNoBackGround
              variant="contained"
              endIcon={<StyledUpdateIcon />}
              onClick={openModal}
            >
              Rastreio
            </StyledButtonNoBackGround>
          </StyledContainer>
        </>
      );
    } else {
      return (
        <>
          <TrackingModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            openModal={openModal}
            sourceOfInformation={trackingInfo.sourceOfInformations}
            service={trackingInfo.serviceTracking}
            date={trackingInfo.dateTracking}
            onUpdateSourceOfInformation={handleUpdateSourceOfInformation}
            onUpdateService={handleUpdateService}
            onUpdateDate={handleUpdateDate}
            origin={origin}
            setModalSaved={setModalSaved}
            sourceInformationDisabled={sourceOfInformation}
            serviceDisabled={service}
            dateDisabled={date}
          />

          <StyledContainer>
            <StyledDivInput>
              <Select
                placeholder="Auto Completar"
                id={`outlined-start-adornment-${attributeName}`}
                options={(item?.domains || []).map((domain: string) => ({
                  value: domain,
                  label: domain,
                }))}
                disabled={!attributeDescription}
                value={attributeValue || ''}
                propConfig={{ unique: 'value', label: 'label' }}
                onChange={(value) =>
                  handleChangeInput(
                    attributeName,
                    group,
                    value,
                    attributeDescription,
                    trackingInfo.sourceOfInformations,
                    trackingInfo.serviceTracking,
                    trackingInfo.dateTracking,
                  )
                }
              />
            </StyledDivInput>

            <StyledButtonNoBackGround
              variant="contained"
              endIcon={<StyledUpdateIcon />}
              onClick={openModal}
            >
              Rastreio
            </StyledButtonNoBackGround>
          </StyledContainer>
        </>
      );
    }
  };

export default CustomInput;
