import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { createContext, useContext, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import type {} from '@mui/x-data-grid/themeAugmentation';

type CustomThemeContext = Record<string, never>;

const customThemeContext = createContext({} as CustomThemeContext);

const context = {};

// extensions go here
// https://mui.com/material-ui/customization/theming/
const theme = createTheme({
  palette: {
    primary: {
      main: `#00B5AD`,
      light: '#F4F4F4',
      dark: '#038983',
    },
    grey: {
      100: '#FFFFFF',
    },
  },
});

export function AppThemeProvider({ children }: { children: ReactNode }): JSX.Element {
  return (
    <MuiThemeProvider theme={theme}>
      <customThemeContext.Provider value={context}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </customThemeContext.Provider>
    </MuiThemeProvider>
  );
}

export function useCustomTheme(): CustomThemeContext {
  const context = useContext(customThemeContext);

  if (!context.appTheme)
    throw new Error('Please make sure to use Custom Theme hook within its provider');

  return context;
}
