import { styled, MuiTabs, Tab, grey } from 'components/Material';

import { CustomTabsProps } from './types';

interface StyledTabProps {
  label: string | JSX.Element;
}

const Wrapper = styled(MuiTabs)(({ theme }) => ({
  minHeight: 36,

  // required to make box shadow be displayed correctly
  borderRadius: '10px 10px 0 0',

  '& .MuiTabs-flexContainer': {
    gap: 8,
    borderRadius: '10px 10px 0 0',
  },

  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    top: 0,
  },

  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },

  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.common.white,
  },
}));

const SingleTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    color: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '10px 10px 0 0',
    boxShadow: theme.shadows[2],
    border: `1px solid ${theme.palette.grey[300]}`,

    '&.Mui-selected': {
      borderColor: 'transparent',
      backgroundColor: theme.palette.primary.main,
      span: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
      },
    },

    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.main,
    },

    '&.MuiButtonBase-root': {
      color: grey[500],
      minHeight: 36,
      padding: '8px 12px',
    },
  }),
);

function FilledTabs({ onChange, tabs, tab }: CustomTabsProps): JSX.Element {
  return (
    <Wrapper value={tab} onChange={(_, value) => onChange(value)}>
      {tabs.map(({ getLabel, id }) => (
        <SingleTab key={id} label={getLabel('')} />
      ))}
    </Wrapper>
  );
}

export default FilledTabs;
