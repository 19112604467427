import {
  convertPixelToRem,
  flex,
  getBoxWidthAndHeight,
  getMargin,
  getPadding,
} from 'css-blocks-styled-components';
import styled from 'styled-components';

export const ErrorContainer = styled.div`
  ${getPadding(0, 20)};
  height: 100vh;

  ${flex.middle}

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      ${getBoxWidthAndHeight(70)};
      color: ${({ theme }) => theme.palette.primary.main};
    }

    h2 {
      ${getMargin(15, 0)}
      font-size: ${convertPixelToRem(34)};
      line-height: ${convertPixelToRem(40)};
      text-align: center;
    }
  }
`;
