import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import DataItemRenderer from '../Accordion/TypeOfTrackingAccordion';
import Scroller from '../Scroll/Scroller';
import { DataItem } from 'pages/Patient/types';

interface DataListProps {
  origin: string;
  patientData: {
    anamnesis: DataItem[];
    clinicalNotes: DataItem[];
    origin: DataItem[];
    prescription: DataItem[];
  };
  searchTerm: string;
  titleKey: string;
  HEIGHT: number;
}

const VirtuosoDataList: React.FC<DataListProps> = ({
  origin,
  patientData,
  searchTerm,
  titleKey,
  HEIGHT,
}) => {
  let data: DataItem[] = [];

  switch (origin) {
    case 'anamnese':
      data = patientData.anamnesis;
      break;
    case 'clinicalNotes':
      data = patientData.clinicalNotes;
      break;
    case 'origin':
      data = patientData.origin;
      break;
    case 'prescription':
      data = patientData.prescription;
      break;
    default:
      break;
  }

  return (
    <>
      {data.length === 0 ? (
        <div>Nenhum dado encontrado.</div>
      ) : (
        <Virtuoso
          data={data}
          components={{ Scroller }}
          itemContent={(index) => (
            <DataItemRenderer
              item={data[index]}
              index={index}
              searchTerm={searchTerm}
              titleKey={titleKey}
            />
          )}
          style={{ height: `${HEIGHT}px` }}
        />
      )}
    </>
  );
};

export default VirtuosoDataList;
