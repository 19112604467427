import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 73px;
  height: 24px;
  border-radius: 15px;
  background: #e2e2e2;
  cursor: pointer;
  transition: background 0.3s;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 2px;
    background: #ffffff;
    position: relative;
    transition: 0.3s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 73px;
  height: 25px;

  &:checked + ${CheckBoxLabel} {
    background: #00b5ad;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 50px;
      transition: 0.3s;
    }
  }

  &:disabled + ${CheckBoxLabel} {
    background: #cccccc;
    cursor: not-allowed;
    &::after {
      background: #e6e6e6;
    }
  }
`;

export const Text = styled.span<Props>`
  position: absolute;
  top: 4px;
  left: ${(props) => (props.isActive ? '9px' : '24px')};
  color: ${(props) => (props.isActive ? 'white' : '#474D59')};
  font-weight: 400;
  font-size: 12px;
`;
