import styled, { css } from 'styled-components';
import {
  flex,
  noBorderNoOutline,
  convertPixelToRem,
  getBoxWidthAndHeight,
} from 'css-blocks-styled-components';

import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';

import { QUERIES } from 'utils/constants';

export const TransparentButton = styled.button<{ $hoverOpacityShift?: boolean }>`
  ${flex.middle}

  ${noBorderNoOutline};

  background: transparent;

  ${({ $hoverOpacityShift }) =>
    $hoverOpacityShift &&
    css`
      ${QUERIES.HOVER_ENABLED} {
        transition: opacity 0.35s ease;

        &:hover {
          opacity: 0.75;
        }
      }
    `};
`;

export const StyledButton = styled(Button)`
  && {
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    height: 40px;

    min-width: 120px;
  }
`;

export const StyledButtonNoBackGround = styled(Button)`
  && {
    border-radius: 8px;
    border: 1px solid #00b5ad;
    padding: 5px 20px;
    font-size: 16px;
    text-transform: capitalize;
    color: #00b5ad;
    background: none;
    box-shadow: none;
    height: 45px;

    &:hover {
      color: #ffffff;
      background: #038983;

      svg {
        color: #ffffff;
      }
    }

    &:disabled {
      border: none;
    }
  }
`;

export const StyledButtonNoBackGroundGray = styled(Button)`
  && {
    border-radius: 5px;
    border: 1px solid #636973;
    padding: 5px 20px;
    font-size: 16px;
    text-transform: capitalize;
    color: #636973;
    background: none;
    box-shadow: none;

    &:hover {
      color: #ffff;
      border: 1px solid #038983;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    width: ${convertPixelToRem(35)};
    height: ${convertPixelToRem(35)};
  }
`;

export const StyledRemoveButton = styled(IconButton)<{ $size: number }>`
  svg {
    ${({ $size }) => getBoxWidthAndHeight($size)}
  }
`;
